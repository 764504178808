import { BooleanEnumType } from "aws-sdk/clients/workdocs";
import { Component, ReactNode, useState } from "react";
import { AiOutlineRotateRight, AiOutlineVerticalLeft } from "react-icons/ai";
import { BsFillCalendarFill } from "react-icons/bs";
import { RiCreativeCommonsZeroLine } from "react-icons/ri";
import { VoidExpression } from "typescript";
import { fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { withHooksHOC } from "../Notifications/NotificationProvider";
import { GiHazardSign } from "react-icons/gi";
import { getCurrentUser } from "aws-amplify/auth";

type Props = {
  title: string;
  handleModalToggle: any;
  ErrorNote: any;
  onoff: boolean;
  id: number;
};

interface ModalState {
  unit_id: number;
  start_date: any;
  end_date: any;
  reason: string;
  other_reason: string;
  onoff: boolean;
  disabled: boolean;
}

class NewBlackout extends Component<Props, ModalState> {
  constructor(props: any) {
    super(props);
    this.state = {
      unit_id: this.props.id,
      start_date: new Date(),
      end_date: new Date(),
      reason: "New Unit", //Default first order, must change this manually if order is different
      other_reason: "",
      onoff: props.onoff,
      disabled: false,
    };
  }

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleClick = async (event: any) => {
    event.preventDefault();
    let NotificationHandler = this.props.ErrorNote;
    console.log(this.state.start_date, this.state.end_date);
    let start_date2 = new Date(this.state.start_date);
    let end_date2 = new Date(this.state.end_date);
    let this_user = await getCurrentUser();
    this.setState({ disabled: true });

    console.log(this.state.unit_id);

    if (this.state.reason === "other" && this.state.other_reason === "") {
      alert("Please enter a reason for the blackout");
      this.setState({ disabled: false });
      return;
    }

    const blackoutBody = {
      unit_id: this.props.id == -1 ? this.state.unit_id : this.props.id,
      start_date: start_date2.toString(),
      end_date: end_date2.toString(),
      reason:
        this.state.reason === "other"
          ? this.state.other_reason
          : this.state.reason,
      created_by: this_user.username,
    };
    console.log(this.props.id);
    console.log(blackoutBody);
    let res = await fetchRequestJSON(
      "POST",
      Constants.API_PATH_BLACKOUT_CREATE,
      blackoutBody
    );
    console.log(res);
    if (res.status != "Success") {
      this.setState({ disabled: false });
      alert(`Please verify you entered your data correctly: ${res.status}`);
    } else {
      let update = store.getState().blackoutReducer.blackoutListUpdate;
      this.setState({ disabled: true });
      console.log(update);
      if (typeof update === "function") {
        update();
      }

      NotificationHandler(
        "Success",
        "Please go to blackouts page to view newly created blackout"
      );
    }
    this.props.handleModalToggle();
    // window.location.reload();
  };

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      this.setState({ onoff: !this.state.onoff });
    }
  };

  componentDidMount = () => {
    if (this.props.id) {
      this.setState({ unit_id: this.props.id });
      this.setState({ disabled: false });
    }
  };

  render() {
    let other = <></>;
    if (this.state.reason == "other") {
      other = (
        <>
          <input
            id="other_reason"
            value={this.state.other_reason}
            onChange={this.handleInputChange}
            type="text"
            className="mt-3 py-3 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder="eg. Owner is selling."
          />
        </>
      );
    }
    if (!this.props.onoff) {
      return null;
    } else {
      return (
        <div
          id="outsideDiv"
          onClick={this.handleModal}
          className={
            "fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40 "
          }
        >
          <div className=" appear bg-white shadow-lg rounded-lg h-auto w-full border-4  border-gray-600 max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start">
            {/* <IoIosArrowBack className="text-white text-3xl absolute transform -translate-x-28 -translate-y-6" /> */}
            <GiHazardSign className="text-yellow-600 text-3xl mb-3" />

            <span className="text-xl font-bold text-gray-800 text-left">
              {this.props.title}
            </span>
            <span className="text-xs text-center text-gray-400"></span>
            <form>
              <div className="grid grid-cols-3 gap-6 w-full my-5">
                <label className="block  ">
                  <span className="text-gray-400 text-xs">
                    <b>Unit ID</b>
                  </span>
                  <input
                    id="unit_id"
                    onChange={this.handleInputChange}
                    onWheel={(e) => {
                      e.currentTarget.blur();
                    }}
                    defaultValue={this.props.id}
                    type="number"
                    className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                    placeholder="eg. 1"
                  />
                </label>

                <label className="block ">
                  <span className="text-gray-400 text-xs">
                    <b>Start Date:</b>
                  </span>
                  <input
                    id="start_date"
                    onChange={this.handleDateChange}
                    defaultValue={this.state.start_date}
                    type="date"
                    className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                    placeholder=""
                  />
                  {/* <span className="text-2xs pt-1 text-gray-300 ">Auto Searches Building</span> */}
                </label>

                <label className="block ">
                  <span className="text-gray-400 text-xs">
                    <b>End Date:</b>
                  </span>
                  <input
                    id="end_date"
                    onChange={this.handleDateChange}
                    defaultValue={this.state.end_date}
                    type="date"
                    className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                    placeholder=""
                  />
                </label>

                <label className="block ">
                  <span className="text-gray-400 text-xs">
                    <b>Reason:</b>
                  </span>
                  {/* <input
                  id="reason"
                  onChange={this.handleInputChange}
                  value={this.state.reason}
                  type="text"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder="eg. Owner is selling."
                /> */}
                  <select
                    id="reason"
                    value={this.state.reason}
                    onChange={this.handleInputChange}
                    className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                    // placeholder="eg. Owner is selling."
                  >
                    {/* DO NOT MOVE THE FIRST OPTION (New Unit) UNLESS YOU ARE WILLING TO CHANGE CONSTRUCTOR*/}
                    <option value="new-unit">New Unit</option>{" "}
                    <option value="gap">Push Within Gap</option>
                    <option value="large-group">Large Group</option>
                    <option value="reno-help">Renovations/Help Tasks</option>
                    <option value="smoking">Smoking</option>
                    <option value="holiday">Holiday</option>
                    <option value="deep-clean">Deep Clean</option>
                    <option value="unit-offboarding">
                      Unit Offboarding, Furniture Removal Pending
                    </option>
                    <option value="other">Other</option>
                  </select>
                  {other}
                </label>
              </div>

              <div className="flex flex-row justify-start w-full mt-6">
                <button
                  onClick={this.handleClick}
                  className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
                  disabled={this.state.disabled}
                >
                  Add Blackout
                </button>
              </div>
            </form>
          </div>
        </div>
      );
    }
  }
}

export default withHooksHOC(NewBlackout);
