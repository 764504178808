interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  component?: any;
  data?: any;
}

const ConfirmationModal = (props: Props) => {
  if (!props.isOpen) return null;

  const formatKey = (key: string) => {
    return key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const renderValue = (value: any, keyName?: string) => {
    if (Array.isArray(value)) {
      return (
        <div>
          {keyName && (
            <span className="font-medium text-green-500">
              {formatKey(keyName)}:
            </span>
          )}
          <ul className="ml-4 list-disc">
            {value.map((item, index) => (
              <li key={index}>
                {typeof item === "object" ? renderValue(item) : item.toString()}
              </li>
            ))}
          </ul>
        </div>
      );
    } else if (typeof value === "object" && value !== null) {
      return (
        <div>
          {keyName && (
            <span className="font-medium text-green-500">
              {formatKey(keyName)}:
            </span>
          )}
          <ul className="ml-4 border-l-2 pl-2 border-gray-300">
            {Object.entries(value).map(([subKey, subValue]) => (
              <li key={subKey}>
                <span className="font-medium text-gray-700">
                  {formatKey(subKey)}:
                </span>{" "}
                {renderValue(subValue)}
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <span>
          {keyName && (
            <span className="font-medium text-gray-700">
              {formatKey(keyName)}:
            </span>
          )}{" "}
          {value.toString()}
        </span>
      );
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999999]"
      onClick={props.onClose}
    >
      <div
        className="bg-white p-6 rounded shadow-md text-center z-[9999999] max-h-[80vh] max-w-[60vw] overflow-y-auto appear"
        onClick={(event) => event.stopPropagation()}
      >
        {props.title ? (
          <p className="text-2xl font-bold text-gray-800">{props.title}</p>
        ) : (
          <p>Are you sure you want to submit?</p>
        )}

        {props.component && <div className="mt-4 mb-5">{props.component}</div>}

        {props.data && (
          <div className="mt-4 p-4 border shadow-md rounded-lg text-left">
            <h3 className="text-lg font-semibold text-green-500 mb-2">
              <u>Details:</u>
            </h3>
            <ul className="space-y-1">
              {Object.entries(props.data).map(([key, value]) => (
                <li key={key}>{renderValue(value, key)}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="mt-4 space-x-5">
          <button
            type="button"
            className="bg-blue-500 text-white text-lg px-4 py-2 rounded mr-2 hover:bg-blue-600 active:bg-blue-700"
            onClick={props.onConfirm}
          >
            Yes
          </button>
          <button
            className="bg-gray-500 text-white text-lg px-4 py-2 rounded hover:bg-gray-600 active:bg-gray-700"
            onClick={(event) => {
              event.preventDefault(); // Prevents default action (useful if inside a form)
              event.stopPropagation(); // Stops event from bubbling up
              props.onClose();
            }}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
