import React, { Component } from "react";
import { RiExchangeDollarLine } from "react-icons/ri";
import { fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { withHooksHOC } from "../Notifications/NotificationProvider";
import ToggleSwitch from "../ToggleSwitch";
import Loading from "../LoadingWidget";
import { inputISO } from "../Units/NewUnit";
const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

interface Props {
  title: string;
  handleModalToggle: any;
  onoff: boolean;
  bookingId: number;
  ErrorNote: any;
  checkIn: string;
}

interface ModalState {
  title: string;
  onoff: boolean;
  rate_change_date: any;
  rate: number;
  monthlyRate: number;
  unit: string;
  toggleDate: boolean;
  changeRateDate: any;
  isLoading: boolean;
}

class RateModal extends Component<Props, ModalState> {
  constructor(props: any) {
    super(props);
    this.state = {
      title: this.props.title,
      onoff: this.props.onoff,
      rate_change_date: "",
      rate: 0,
      monthlyRate: 0,
      unit: "",
      toggleDate: false,
      changeRateDate: new Date(),
      isLoading: false,
    };
  }

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      this.setState({ onoff: !this.state.onoff });
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.checkIn !== this.props.checkIn) {
      this.setState({
        changeRateDate: new Date(this.props.checkIn),
      });
    }
  }

  handleRateChange = (event: any) => {
    event.preventDefault();
    const monthlyRate = event.target.value * 30;
    this.setState({
      rate: event.target.value,
      monthlyRate: monthlyRate,
    });
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    console.log(event.target.value);
    this.setState({
      changeRateDate: event.target.value,
    });
  };

  handleConfirm = async () => {
    let check = !this.state.toggleDate;
    await this.setState({
      toggleDate: check,
    });
    this.setState({
      changeRateDate: check == true ? new Date(this.props.checkIn) : new Date(),
    });
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();
    this.setState({ isLoading: true }); // Start loading
    const NotificationHandler = this.props.ErrorNote;

    try {
      const convertToUTC = dayjs.utc(this.state.changeRateDate, "YYYY-MM-DD");
      const rateChangeDate = await fetchRequestJSON(
        "PUT",
        Constants.API_PATH_BOOKING_EDIT_RATE,
        {
          bookingId: this.props.bookingId,
          rateChange: this.state.monthlyRate,
          timeToChange: convertToUTC.format("YYYY-MM-DDTHH:mm:ss"),
        }
      );

      if (rateChangeDate.status === "Success") {
        NotificationHandler(
          "Success",
          `Successfully Changed Booking Rate`,
          "Refresh Booking Profile to see when rate change will take into effect"
        );
      } else {
        NotificationHandler(
          "Error",
          `Error Changing Rate contact Dev Team`,
          `${rateChangeDate.status}`
        );
      }
    } catch (error) {
      NotificationHandler(
        "Error",
        "Error Changing Rate",
        "Please Check Sales Order and Booking Profile for Rate Change Modifications"
      );
    } finally {
      this.setState({ isLoading: false }); // Stop loading
    }
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className="fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-40"
      >
        <div
          className="appear bg-white shadow-lg rounded-lg border-4 border-gray-600 max-w-screen-md py-8 px-10 mx-auto flex flex-col items-start justify-start relative"
          style={{ minHeight: "300px", minWidth: "600px" }} // Ensure consistent size
        >
          {this.state.isLoading && (
            <div className="absolute inset-0 flex flex-col justify-center items-center bg-white bg-opacity-75 z-50">
              <h1 className="mb-10 text-xl font-bold text-gray-700 text-center">
                Applying the rate change to the booking and associated sales
                order. This may take a moment
              </h1>
              <Loading size="3x-large" type="big-spiral" />
            </div>
          )}
          {!this.state.isLoading && (
            <>
              <RiExchangeDollarLine className="text-gray-600 text-3xl mb-3" />
              <span className="text-xl font-bold text-gray-800 text-left">
                {this.state.title}
              </span>
              <form>
                <div className="grid grid-cols-3 gap-6 w-full my-5">
                  <label className="block">
                    <span className="text-gray-400 text-xs">
                      New <strong>Nightly</strong> Rate:
                    </span>
                    <input
                      id="rate"
                      onChange={this.handleRateChange}
                      onWheel={(e) => e.currentTarget.blur()}
                      value={this.state.rate}
                      type="number"
                      className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                      placeholder="Enter new nightly rate"
                    />
                  </label>
                  <label className="block">
                    <span className="text-gray-400 text-xs">Monthly Rate:</span>
                    <input
                      id="rate"
                      value={this.state.monthlyRate}
                      type="number"
                      className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                      placeholder="Monthly rate"
                      disabled
                    />
                  </label>
                  <label className="block  ">
                    <span className="text-gray-400 text-xs">
                      Date of Rate Change:
                    </span>
                    <input
                      id="transfer_date"
                      onChange={this.handleDateChange}
                      value={this.state.changeRateDate}
                      type="date"
                      className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                      placeholder="Select the Transfer Date"
                    />
                  </label>
                  <label className="block ">
                    <span className="text-gray-400 text-xs">
                      Date Selected (in EST):
                    </span>
                    <input
                      id="rate"
                      onChange={this.handleRateChange}
                      value={dayjs(this.state.changeRateDate)
                        .tz("America/Toronto")
                        .format("LLL")}
                      type="string"
                      className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                      placeholder="Please enter the new rate here"
                      disabled
                    />
                  </label>
                </div>
                <div className="flex flex-row mb-2 sm:pr-2">
                  <span className="text-gray-600 text-sm mr-3">
                    Toggle to change rate for full booking
                  </span>
                  <ToggleSwitch
                    id="selectDate"
                    handleStateToggle={this.handleConfirm}
                    className="justify-center align-center"
                    size="lg"
                    onoff={this.state.toggleDate}
                  />
                </div>
                <div className="flex flex-row justify-start w-full mt-6">
                  <button
                    onClick={this.handleSubmit}
                    className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800 rounded-sm text-white shadow-sm"
                  >
                    Change Rate
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withHooksHOC(RateModal);
