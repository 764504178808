import { Component, useState } from "react";
import { Box, Modal, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/lab";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { ImCross } from "react-icons/im";
import { useNotification } from "../Notifications/NotificationProvider";
import { getCurrentUser } from "aws-amplify/auth";
import { getUserList } from "../../api-requests/api-gateway";
import { idText } from "typescript";

type Props = {
  id: number;
  checkOut: string;
  open: boolean;
  closeModal: any;
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function ShortenCreateModal(props: Props) {
  const classes = useStyles();
  const thisDate = new Date(props.checkOut);
  const [date, setDate] = useState(thisDate.toISOString().split("T")[0]);
  const [open, setOpen] = useState(props.open);
  const [users, setUser] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useNotification();

  function onDateChange(event: any): void {
    event.preventDefault();
    setDate(event.target.value);
  }

  function HandleError(type: string, title: string, message: string) {
    dispatch({
      type: type.toUpperCase(),
      title: title,
      message: message,
    });
  }

  async function submitShortenRequest() {
    //Put below code as a helper function next time
    setDisabled(true);
    const res = await getCurrentUser();

    const userList: any = await getUserList();
    await setUser(userList.Users);

    const user = userList.Users.find(
      (user: any) => user.Username == res.username
    );
    let userName = null;
    if (user) {
      let name = user.Attributes.find((attr: any) => attr.Name == "name");
      if (name) {
        userName = name;
      }
    }

    //TEMP FIX FOR BELOW:
    const url = window.location.href;
    let bookingIdPosition = url.indexOf("id");

    const resp = await fetchRequestJSON(
      "PUT",
      Constants.API_PATH_BOOKING_SHORTEN,
      {
        shorten: date,
        id: url.substring(bookingIdPosition + 3),
        user: userName.Value,
      }
    );
    if (resp.status === "Success") {
      // HandleError("Success","Successfully shortened booking", "");
      props.closeModal();
      window.location.reload();
    } else if (resp.status === "Error: Booking has been cancelled") {
      HandleError("Error", "This booking has been cancelled", "");
    } else if (
      resp.status ===
      "Error: A tentative extension exists (please approve or disapprove the current extension request)."
    ) {
      HandleError(
        "Error",
        "A tentative extension exists",
        "Please approve or disapprove the existing extension request"
      );
    } else if (
      resp.status.includes("checkout date must be earlier than the current one")
    ) {
      HandleError(
        "Error",
        "The checkout date must be earlier than the current check out date",
        ""
      );
    } else if (
      resp.status.includes("checkout date must be later than the check-in date")
    ) {
      HandleError(
        "Error",
        "The checkout date must be later than the check-in date",
        ""
      );
    } else {
      HandleError(
        "Error",
        "Error",
        resp?.data ? resp.data : "Contact Dev Team"
      );
    }
    setDisabled(false);
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={props.open}
      onClose={() => props.closeModal()}
    >
      <div className=" appear bg-white shadow-lg rounded-lg h-2/5 w-1/2 border-4  border-gray-600 py-8 px-10 mx-auto flex flex-col align-self-center">
        {/* <button className="" onClick={() => {
                        setOpen(false); 
                    }}>
                        <ImCross className="text-gray-600 text-s mb-3" />
                    </button> */}
        <span className="text-xl font-bold text-gray-800 text-left pb-4">
          {" "}
          Shorten Request
        </span>
        {/* <DatePicker value={date} onChange={(newDate) => onDateChange(newDate)} renderInput={(params) => <TextField {...params} />}></DatePicker> */}
        <label className="pl-3 mb-5 mx-auto flex flex-col">
          <span className="text-gray-400 text-xs">New Checkout Date</span>
          <input
            id="date"
            onChange={(e) => onDateChange(e)}
            value={date}
            type="date"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>
        <div className="h-24 mx-auto flex flex-col">
          <button
            onClick={() => submitShortenRequest()}
            className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
            disabled={disabled}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
}

export { ShortenCreateModal };
