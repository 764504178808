interface Props {
  bookingId: number;
  addOnsData: any;
}

const AddOnsProfileCard = (props: Props) => {
  return (
    <div className=" slide-in-blurred-top  font-display border px-8 py-4 shadow-lg rounded-md">
      <h1 className="text-xl font-bold mb-4">
        Add On's for Booking #{props.bookingId}
      </h1>
      {props.addOnsData.length > 0 ? (
        <div className="flex-col w-full">
          <table className="hidden lg:table table-auto w-full mb-8 border-collapse border border-gray-200">
            <thead>
              <tr className="text-left text-gray-500 border-b border-gray-300">
                <th className="py-2 font-thin px-4">Add-On</th>
                <th className="py-2 font-thin px-8">Price</th>
                <th className="py-2 font-thin px-4">Description</th>
              </tr>
            </thead>
            <tbody>
              {props.addOnsData.map((addOn: any) => (
                <tr
                  key={addOn.id}
                  className="border-t border-gray-300 text-gray-800"
                >
                  <td className="text-sm text-green-500 lg:text-md font-bold px-4 py-4">
                    {addOn.name}
                  </td>
                  <td className="text-xs text-indigo-500 lg:text-sm px-8 py-4">
                    ${addOn.BookingsAddOns[0].custom_price}
                  </td>
                  <td className="font-thin text-xs lg:text-sm text-gray-600 px-4 py-4">
                    {addOn.description}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-gray-600">
          There are no add-ons associated with this booking.
        </div>
      )}
    </div>
  );
};

export default AddOnsProfileCard;
