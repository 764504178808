import { Component, useState } from "react";
import { MdSearch } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import React from "react";
import AWS, { S3 } from "aws-sdk";
import { Constants } from "../../../api-requests/apiLinkConstants";
import store from "../../../redux/store";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../../api-requests/apiCallHelper";

type Props = {};
type State = {
  files: string[];
};

class FilesToLoad extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      files: [],
    };
  }

  componentDidMount = async () => {
    this.updateFileList();
    store.dispatch({ type: "fileListUpdate", listUpdate: this.updateFileList });
  };

  handleDownload = async (key: string) => {
    const encodedKey = encodeURIComponent(key);
    const request = await fetchGetJSON(
      `${Constants.API_PATH_FILES_DOWNLOAD}?key=${encodedKey}`
      // { key: key }
    );
    window.open(request.data);
  };

  updateFileList = async () => {
    let unitId = Number(new URLSearchParams(window.location.search).get("id"));
    let response = await fetchGetJSON(
      `${Constants.API_PATH_FILES_LIST}?id=${unitId}&category=unit`
      // "GET",
      // `${Constants.API_PATH_FILES_LIST}`,
      // {
      //   id: unitId,
      //   category: "unit",
      // }
    );
    // console.log("updated");

    let filesList: string[] = [];
    if (response.status === "Success") {
      let contentsInfo: S3.ListObjectsOutput = response.data;
      contentsInfo.Contents.forEach((file: S3.Object) => {
        filesList.push(file.Key);
      });
      this.setState({ files: filesList });
    }
  };

  handleDelete = async (key: string) => {
    // console.log("delete");
    await fetchRequestJSON(
      "DELETE",
      `${Constants.API_PATH_FILES_DELETE}?key=${key}`
    );
    this.updateFileList();
  };

  render() {
    let rows = [];
    let Files = <></>;
    let altBg = "";
    let length = 2;
    for (let i = 0; i < this.state.files.length; i++) {
      //when linking to back end: remember to switch the key to the unique invoice id from the backend
      const name = this.state.files[i];
      Files = (
        <tr
          key={i.toString()}
          className={
            " w-full  hover:bg-gray-100 h-12 border-b border-gray-400  "
          }
        >
          <td className="py-4 pl-3 text-purple-400 font-thin ">{name}</td>
          <td className=" text-right ">
            <button
              onClick={() => this.handleDownload(name)}
              className=" h-8 w-auto mr-3 px-2 border border-gray-400 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white "
            >
              Download
            </button>
            <button
              onClick={() => this.handleDelete(name)}
              className=" h-8 w-auto mr-3 px-2 border border-gray-400 text-xs font-display text-gray-600 hover:bg-gray-300 hover:text-white "
            >
              Delete
            </button>
          </td>
        </tr>
      );

      rows.push(Files);
    }

    return <>{rows}</>;
  }
}
export default withRouter(FilesToLoad);
