import { Component } from "react";
import { createTheme, PaletteColorOptions, TextField } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import {
  fetchGetJSON,
  fetchRequestJSON,
  regGet,
  regRequest,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FiCheckCircle } from "react-icons/fi";
import Checkbox from "@mui/material/Checkbox";
import { withHooksHOC } from "../Notifications/NotificationProvider";
import Loading from "../LoadingWidget";
import Logo from "../../client-facing/img/SKYVIEWSUITESDark.png";

declare module "@mui/material/styles" {
  interface CustomPalette {
    forestGreen: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    forestGreen: true;
  }
}
declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    forestGreen: true;
  }
}
declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    forestGreen: true;
  }
}
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: any) =>
  augmentColor({ color: { main: mainColor } });
const theme = createTheme({
  palette: {
    forestGreen: createColor("#03937F"),
  },
});

interface Props {
  ErrorNote: any;
  history: any;
}

interface State {
  bookingId: number;
  bookingConfirmInfo: any;
  termsOfService: boolean;
  confirmedInfo: boolean;
  confirmedBooking: boolean;
  clickedSubmit: boolean;
  loading: boolean;
  emails: string;
  firstRentLink: string;
  confirmedPhone: boolean;
}

class ConfirmBookingForm extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      bookingId: -1,
      bookingConfirmInfo: {},
      termsOfService: false,
      confirmedInfo: false,
      confirmedBooking: false,
      clickedSubmit: false,
      loading: false,
      emails: "",
      firstRentLink: null,
      confirmedPhone: false,
    };
  }

  validate = () => {
    return this.state.termsOfService && this.state.confirmedInfo;
  };

  handleCheckbox = (id: any, state: boolean) => {
    let stateObject = (function (this: typeof id) {
      let returnObj: any = {};
      returnObj[id] = !state;
      return returnObj;
    })();
    this.setState(stateObject);
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();

    this.setState({ clickedSubmit: true });

    // If net0, show payment. Else, show booking confirmed page
    if (this.state.bookingConfirmInfo.isNetZero && this.state.firstRentLink) {
      window.location.href = this.state.firstRentLink;
    } else {
      const sig = new URLSearchParams(window.location.search).get("signature");

      this.setState({ loading: true });
      const notificationHandler = this.props.ErrorNote;
      notificationHandler(
        "Success",
        "Please do not exit the page",
        "Please wait while we confirm your booking information."
      );

      const submitForm = await regRequest(
        "PUT",
        `${Constants.API_PATH_BOOKING_CONFIRMATIONFORM_SUBMIT}${this.state.bookingId}?signature=${sig}`
      );
      // const submitForm = await fetchRequestJSON("POST",`${Constants.API_PATH_BOOKING_CONFIRMATIONFORM_SUBMIT}${this.state.bookingId}?signature=8f6aa3edcbf7e97d5c8e075d8c2dfa70499422909ec5b3c6bdc1eafd880338fed2a45e68dca9ade8403d2c0b59ce38209cc662f73d5f9b2685c9fae07e803cc1`);
      if (submitForm.status === "Success") {
        this.setState({
          confirmedBooking: true,
          loading: false,
        });
      } else if (submitForm.status === "Error") {
        this.setState({ loading: false });
        const notificationHandler = this.props.ErrorNote;
        notificationHandler("Error", "Submission form failed", submitForm.data);
      } else {
        this.setState({ loading: false });
        const notificationHandler = this.props.ErrorNote;
        notificationHandler(
          "Error",
          "Submission form failed",
          "Please contact help@skyviewsuites.com for support."
        );
      }
    }
  };

  componentDidMount = async () => {
    const sig = new URLSearchParams(window.location.search).get("signature");
    // get booking id
    const pathArr = window.location.pathname.split("/");
    const id = Number(pathArr.pop());
    await this.setState({
      bookingId: id,
    });

    const bookingInfo = await regGet(
      `${Constants.API_PATH_BOOKING_CONFIRMATION_DATA}${this.state.bookingId}?signature=${sig}`
    );
    // const bookingInfo = await fetchGetJSON(`${Constants.API_PATH_BOOKING_CONFIRMATION_DATA}${this.state.bookingId}?signature=${sig}`);
    if (bookingInfo.status === "Success") {
      this.setState({
        bookingConfirmInfo: bookingInfo.data,
        confirmedBooking: bookingInfo.data.booking?.confirmed
          ? bookingInfo.data.booking.confirmed
          : false,
        firstRentLink: bookingInfo.data.link,
      });

      let emails = "";
      if (Array.isArray(bookingInfo.data?.email)) {
        for (let i = 0; i < bookingInfo.data.email.length; i++) {
          emails += ` ${bookingInfo.data.email[i]}`;
          if (i + 1 !== bookingInfo.data.email.length) {
            emails += " and";
          }
        }
      } else {
        emails += ` ${bookingInfo.data.email}`;
      }

      this.setState({
        emails: emails,
      });
    }
  };

  render() {
    let endButton = <></>;
    let paymentNotif = <></>;
    if (this.state.bookingConfirmInfo.isNetZero && this.state.firstRentLink) {
      endButton = (
        <section className="rowEnd responsive-width sm:mt-0 mt-2 responsive-width-button">
          <Button
            // type="submit"
            // variant="contained"
            color="forestGreen"
            disabled={!this.validate()}
            onClick={this.handleSubmit}
            sx={{
              color: "green",
            }}
          >
            Next
          </Button>
        </section>
      );
      if (this.validate()) {
        paymentNotif = (
          <section className="rowStart sm:flex-row flex-col sm:mb-0 responsive-width mb-16">
            <h2 className="text-green-600 text-center">
              Thank you for confirming your personal information. To complete
              the rest of the booking confirmation process, you will need to
              make the first payment for your booking.
            </h2>
          </section>
        );
      }
    } else {
      endButton = (
        <section className="rowEnd responsive-width sm:mt-0 mt-2 responsive-width-button">
          <Button
            // type="submit"
            // variant="contained"
            color="forestGreen"
            disabled={!this.validate() || this.state.clickedSubmit}
            onClick={this.handleSubmit}
            sx={{
              color: "green",
            }}
          >
            Submit
          </Button>
        </section>
      );
    }

    if (this.state.loading) {
      return (
        <ThemeProvider theme={theme}>
          <div className="flex flex-col h-screen bg-white">
            <div className="flex justify-center">
              <div className="flex w-1/3 mt-8">
                <img src={Logo} alt="Sky View Suites Logo" />
              </div>
            </div>
            <div className="flex justify-center m-auto py-0">
              <div className="flex flex-col w-2/3 py-10 px-5">
                <Loading size="3x-large" type="big-spiral" />
                <h1 className="text-center mt-12 text-xl">
                  We are currently processing your request. Thank you for your
                  patience!
                </h1>
              </div>
            </div>
          </div>
        </ThemeProvider>
      );
    } else if (this.state.confirmedBooking) {
      return (
        <ThemeProvider theme={theme}>
          <div className="flex flex-col sm:h-screen">
            <div className="flex justify-center pb-28">
              <div className="flex w-1/3 mt-8">
                <img src={Logo} alt="Sky View Suites Logo" />
              </div>
            </div>
            <div className="flex justify-center pb-28">
              <div className="flex flex-col sm:p-7 p-3 rounded-lg bg-white shadow-2xl md:max-w-2xl">
                <div className="flex justify-center">
                  <FiCheckCircle className="text-7xl text-green-500 font-bold" />
                </div>
                <h1 className="font-bold text-4xl py-6 text-gray-800 text-center">
                  Thank you for staying with
                  <br />
                  <span className="text-green-500 break-after">
                    {" "}
                    Sky View Suites
                  </span>
                  !
                </h1>
                <div className="flex-col">
                  <div className="justify-center flex">
                    <h2 className="text-xl text-gray-800 mb-7 font-bold text-center">
                      Your booking #{this.state.bookingId} has been confirmed!
                    </h2>
                  </div>
                  <div className="flex">
                    <h3 className="text-l text-gray-800 mb-7 text-center">
                      {" "}
                      A booking summary has been sent to
                      <span className="font-bold"> {this.state.emails}</span>.
                      We are excited to be sharing this experience with you and
                      we hope you enjoy your stay!
                    </h3>
                  </div>
                  <h3 className="text-m text-gray-800 mb-1 text-center">
                    If you have any questions regarding your stay, please
                    contact&nbsp;
                    <a
                      href="mailto:help@skyviewsuites.com"
                      className="text-green-500 underline"
                    >
                      help@skyviewsuites.com
                    </a>
                    &nbsp;for support.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </ThemeProvider>
      );
    } else {
      return (
        <ThemeProvider theme={theme}>
          <div className="flex flex-col h-screen">
            <div className="flex justify-center pb-12">
              <div className="flex w-1/3 mt-8">
                <img src={Logo} alt="Sky View Suites Logo" />
              </div>
            </div>
            <div className="flex justify-center">
              <form className="formCard shadow-lg responsive-width">
                <h1>Booking Confirmation</h1>
                <aside className="spacerY" />

                <section className="rowStart sm:flex-row flex-col mb-2 sm:mb-0 responsive-width">
                  <TextField
                    inputProps={{
                      style: {
                        padding: 12,
                      },
                    }}
                    id="first-name-read-only-input"
                    disabled
                    fullWidth
                    label="First Name"
                    value={
                      this.state.bookingConfirmInfo?.tenant?.firstName
                        ? this.state.bookingConfirmInfo.tenant.firstName
                        : "N/A"
                    }
                  />
                  <aside className="spacerX sm:my-0 my-2" />
                  <TextField
                    inputProps={{
                      style: {
                        padding: 12,
                      },
                    }}
                    id="last-name-read-only-input"
                    disabled
                    fullWidth
                    label="Last Name"
                    value={
                      this.state.bookingConfirmInfo?.tenant?.lastName
                        ? this.state.bookingConfirmInfo.tenant.lastName
                        : "N/A"
                    }
                  />
                </section>
                <aside className="spacerY responsive-width" />

                <section className="rowStart sm:flex-row flex-col sm:mb-0 responsive-width">
                  <TextField
                    inputProps={{
                      style: {
                        padding: 12,
                      },
                    }}
                    id="address-read-only-input"
                    disabled
                    fullWidth
                    label="Address"
                    value={
                      this.state.bookingConfirmInfo?.unit?.location
                        ? this.state.bookingConfirmInfo.unit.location
                        : "N/A"
                    }
                  />
                </section>
                <aside className="spacerY" />

                <section className="rowStart sm:flex-row flex-col sm:mb-0 responsive-width">
                  <TextField
                    inputProps={{
                      style: {
                        padding: 12,
                      },
                    }}
                    id="unit-name-read-only-input"
                    disabled
                    fullWidth
                    label="Unit Type"
                    value={
                      this.state.bookingConfirmInfo?.unit?.suiteType
                        ? this.state.bookingConfirmInfo.unit.suiteType
                        : "N/A"
                    }
                  />
                  <aside className="spacerX sm:my-0 my-2" />
                  <TextField
                    inputProps={{
                      style: {
                        padding: 12,
                      },
                    }}
                    id="rent-read-only-input"
                    disabled
                    fullWidth
                    label="Nightly Rate"
                    value={`${
                      this.state.bookingConfirmInfo?.booking?.monthlyRate
                        ? Math.round(
                            this.state.bookingConfirmInfo.booking.monthlyRate /
                              30
                          )
                        : "N/A"
                    }`}
                  />
                </section>
                <aside className="spacerY responsive-width" />

                <section className="rowStart sm:flex-row flex-col mb-14 sm:mb-0 responsive-width">
                  <TextField
                    inputProps={{
                      style: {
                        padding: 12,
                      },
                    }}
                    id="checkin-read-only-input"
                    disabled
                    fullWidth
                    label="Check In Date"
                    value={
                      this.state.bookingConfirmInfo?.booking?.checkIn
                        ? this.state.bookingConfirmInfo.booking.checkIn
                        : "N/A"
                    }
                  />
                  <aside className="spacerX sm:my-0 my-2" />
                  <TextField
                    inputProps={{
                      style: {
                        padding: 12,
                      },
                    }}
                    id="checkout-read-only-input"
                    disabled
                    fullWidth
                    label="Check Out Date"
                    value={
                      this.state.bookingConfirmInfo?.booking?.checkOut
                        ? this.state.bookingConfirmInfo.booking.checkOut
                        : "N/A"
                    }
                  />
                </section>
                <aside className="spacerY" />

                <section className="rowStart sm:flex-row flex-col mb-14 sm:mb-0 responsive-width">
                  <div className="flex flex-col py-1 px-3 border border-gray-300 rounded-md mx-auto">
                    <h2 className="text-s text-gray-700">House Rules</h2>
                    <div className="flex py-1">
                      <ul className="list-disc ml-7 text-gray-700 mr-0">
                        <li>Do not smoke. A fine of $1000 will be imposed.</li>
                        <li>Lost Keys will result in a $200 fee.</li>
                        <li>Be respectful of others around you.</li>
                      </ul>
                    </div>
                  </div>
                </section>
                <aside className="spacerY" />
                <br />

                <section className="rowStart sm:flex-row flex-col sm:mb-0 responsive-width mt-10">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="forestGreen"
                        value={this.state.termsOfService}
                        onChange={() => {
                          this.handleCheckbox(
                            "termsOfService",
                            this.state.termsOfService
                          );
                        }}
                      />
                    }
                    label={
                      <span>
                        I agree to these house rules and these&nbsp;
                        <a
                          href="https://storage.googleapis.com/svstings/Sky%20View%20Suites%20TOS.pdf"
                          target="_blank"
                          className="text-blue-500 underline"
                        >
                          Terms and Conditions
                        </a>
                        .
                      </span>
                    }
                  />
                </section>
                {/* NEED TO REMOVE THIS AFTER, ASK NEWMAN AND MK WHY WE DID THIS */}
                {this.state.bookingId == 8622 && (
                  <section className="rowStart sm:flex-row flex-col sm:mb-0 responsive-width ">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="forestGreen"
                          value={this.state.confirmedPhone}
                          onChange={() => {
                            this.setState({
                              confirmedPhone: !this.state.confirmedPhone,
                            });
                          }}
                        />
                      }
                      label="I consent to receiving SMS details about my booking"
                    />
                  </section>
                )}

                <section className="rowStart sm:flex-row flex-col sm:mb-0 responsive-width mt-3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="forestGreen"
                        value={this.state.confirmedInfo}
                        onChange={() => {
                          this.handleCheckbox(
                            "confirmedInfo",
                            this.state.confirmedInfo
                          );
                        }}
                      />
                    }
                    label="I confirm that the above information is correct."
                  />
                </section>

                {paymentNotif}
                <aside className="spacerY" />

                {endButton}
              </form>
            </div>
          </div>
        </ThemeProvider>
      );
    }
  }
}

export default withHooksHOC(ConfirmBookingForm);
