import { Component } from "react";
import BreadCrumb from "../BreadCrumb";
import TabList from "../TabList";
import EditOptionPanel from "../EditOptionPanel";
import { BsFillFlagFill } from "react-icons/bs";
import { FaPhoneAlt } from "react-icons/fa";
import { BiTrash } from "react-icons/bi";
import { AiOutlineDownload } from "react-icons/ai";
import { AiOutlinePaperClip, AiOutlineFileText } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";

import Label from "../LabelWrap";
import EditCorporate from "./EditCorporate";

import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import Modal from "../Modal";
import { withHooksHOC } from "../Notifications/NotificationProvider";

const INVOICE_FREQUENCY_LABELS = {
  thirty: "Every 30 Days",
  weekly: "Every Week",
  stay_based: "Per Stay",
  monthly: "Monthly Invoicing",
} as const;

type InvoiceFrequency = keyof typeof INVOICE_FREQUENCY_LABELS;

const INVOICE_TIMING_LABELS = {
  prepayment: "Prepayment",
  due_on_creation: "Due on Creation",
  due_on_arrival: "Due on Arrival",
};

type InvoiceTiming = keyof typeof INVOICE_TIMING_LABELS;

type Props = {
  ErrorNote: any;
  history: any;
};

type State = {
  corporation: string;
  address: string;
  // admin_contact: string;
  // admin_contact_email: string;
  // admin_contact_phone: string;
  // finance_contact: string;
  // finance_contact_email: string;
  // finance_contact_phone: string;
  // cleaning_contact: string;
  // cleaning_contact_email: string;
  // cleaning_contact_phone: string;
  client_manager: string;
  payment_type: string;
  flagged: boolean;
  fin_com_pol: string;
  clean_com_pol: string;
  admin_com_pol: string;
  var_com_pol: boolean;
  id: string;
  invoicing_method: string;
  ntv: number;
  payment_terms: string;
  first_last: string;

  //Contacts
  admin_contacts: object[];
  finance_contacts: object[];
  cleaning_contacts: object[];

  charge_deposit: boolean;
  charge_cleaning: boolean;
  charge_credit_fee: boolean;

  //* Documents
  files: Array<File>;
  file_names: Array<string>;
  web_URL: string;
  final_notes: string;

  corporate_notes: string;
  editToggle: boolean;

  expiry: number;

  payment_timing: string;
  requires_initial_payment: boolean;
  city: string;
  province_state: string;
  postal_code: string;
  country: string;
};

// Notes:
//take a look at array of file objects and create function to populate the rows

class CorporateProfile extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      corporation: "",
      address: "",

      admin_contacts: [],
      cleaning_contacts: [],
      finance_contacts: [],

      // admin_contact_email: "",
      // cleaning_contact_email: "",
      // finance_contact_email: "",

      // admin_contact_phone: "",
      // cleaning_contact_phone: "",
      // finance_contact_phone: "",

      client_manager: "",
      payment_type: "",
      flagged: false,
      fin_com_pol: "",
      clean_com_pol: "",
      admin_com_pol: "",
      var_com_pol: false,
      id: null,
      invoicing_method: "",
      ntv: 0,
      payment_terms: "",
      first_last: "",

      charge_deposit: false,
      charge_cleaning: false,
      charge_credit_fee: false,
      corporate_notes: "",

      //* Documents
      files: [],
      file_names: [],
      web_URL: "",
      final_notes: "",
      editToggle: false,

      expiry: -1,

      //Address:
      city: "",
      province_state: "",
      postal_code: "",
      country: "",
      requires_initial_payment: false,
      payment_timing: "due_on_creation",
    };
  }

  componentDidMount = async () => {
    store.dispatch({ type: "corpViewUpdate", corpViewUpdate: this.updatePage });
    await this.updatePage();
    console.log(this.state);
  };

  updatePage = async () => {
    let contactId = Number(
      new URLSearchParams(window.location.search).get("contact_id")
    );

    let corpId = Number(
      new URLSearchParams(window.location.search).get("corp_id")
    );

    let allContacts: any;
    let contactData: any;
    let allCorps: any;
    let corpData: any;

    allContacts = await fetchGetJSON(
      `${Constants.API_PATH_CORP_CONTACT_GET_ALL}/?corporate_id=${corpId}`
    );
    console.log(allContacts);
    contactData = allContacts.data.find(
      (contactItem: any) => contactItem.id === contactId
    );
    // allCorps = await fetchGetJSON(Constants.API_PATH_CORP_GET_ALL);
    // corpData = allCorps.data.find(
    //   (corpItem: any) => corpItem.id === contactData.corporate_id
    // );
    let corpResponse = await fetchGetJSON(Constants.API_PATH_CORP_GET + corpId);
    corpData = corpResponse.data;

    console.log(corpData);

    this.setState({
      corporation: corpData.corporate_name,
      address: corpData.address,
      client_manager: corpData.client_manager,
      payment_type: corpData.method_of_payment,
      id: corpData.id,
      invoicing_method: corpData.invoicing_method,
      ntv: corpData.notice_to_vacate,
      payment_terms: corpData.payment_terms,
      first_last: corpData.first_last_policy,
      charge_cleaning: corpData.cleaning_fee,
      charge_credit_fee: corpData.credit_fee,
      charge_deposit: corpData.deposit_fee,
      corporate_notes: corpData.notes,

      admin_contacts: allContacts.data.filter(
        (contactItem: any) =>
          contactItem.corporate_id === corpData.id && contactItem.admin
      ),
      finance_contacts: allContacts.data.filter(
        (contactItem: any) =>
          contactItem.corporate_id === corpData.id && contactItem.finance
      ),
      cleaning_contacts: allContacts.data.filter(
        (contactItem: any) =>
          contactItem.corporate_id === corpData.id && contactItem.cleaning
      ),

      fin_com_pol: corpData.finance_communication_policy,
      clean_com_pol: corpData.cleaning_communication_policy,
      admin_com_pol: corpData.admin_communication_policy,
      var_com_pol: corpData.variable_communication_policy,

      expiry: corpData.expiry,

      requires_initial_payment: corpData.requires_initial_payment,
      city: corpData.city,
      province_state: corpData.province_state,
      postal_code: corpData.postal_code,
      country: corpData.country,
      payment_timing: corpData.payment_timing,
    });
  };

  // handleEdit = () => {
  //   alert("opens edit bar for Units");
  // };

  handleEditBarToggle = () => {
    let toggle = this.state.editToggle;
    if (toggle === true) {
      this.setState({ editToggle: false });
    } else {
      this.setState({ editToggle: true });
    }
  };

  handleRemoveFile = (i: number) => {
    let arNames = this.state.file_names;
    let arFiles = this.state.files;
    arNames.splice(i, 1);
    arFiles.splice(i, 1);

    this.setState({
      files: arFiles,
      file_names: arNames,
    });
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = (function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    })();
    this.setState(stateObject);
  };

  toggleFlag = (event: any) => {
    if (this.state.flagged === false) {
      this.setState({ flagged: true });
    } else {
      this.setState({ flagged: false });
    }
  };

  handleFileChange = (event: any) => {
    let fileNames = this.state.file_names;
    fileNames.push(event.target.files[0].name);
    let Files = this.state.files;
    Files.push(event.target.files[0]);

    this.setState({
      files: Files,
      file_names: fileNames,
    });
  };

  handleError = (type: string, title: string, message: string) => {
    store.dispatch({
      type: type.toUpperCase(),
      title: title,
      message: message,
    });
  };

  render() {
    let HeatBuildingTag: any = "";
    let bgFocus;
    let ModelType = "";

    if (this.state.editToggle === true) {
      bgFocus = (
        <div
          onClick={this.handleEditBarToggle}
          className=" fade-bg w-full h-screen bg-black z-40  fixed"
        ></div>
      );
    }

    let flag = <></>;

    if (this.state.flagged) {
      flag = (
        <BsFillFlagFill
          className={"text-red-400 group-hover:text-gray-400 text-2xl mt-1 "}
        />
      );
    }

    let Files: any = <></>;
    let AdditionalFiles = <></>;

    Files = this.state.file_names.map((name: any, index: any) => (
      //   <span className="px-2 fade-in text-gray-600 py-2 flex flex-row items-center hover:bg-gray-100 cursor-default border-b "><AiOutlineFileText className="text-lg mr-1"/>{name} <TiDelete  className="ml-auto text-2xl  hover:text-gray-400 cursor-pointer"/> </span>
      <div
        key={index}
        className="flex flex-row w-full justify-between items-center py-2 border-t  bg-gray-50 border-b"
      >
        <div className="px-4 flex flex-col text-sm">
          <span className="text-gray-600 font-semibold">{name} </span>
          <span className="text-gray-400">Date Uploaded</span>
        </div>

        <div className="flex flex-row text-2xl px-4 ">
          <BiTrash
            onClick={() => this.handleRemoveFile(index)}
            className="mr-2 hover:text-red-500 cursor-pointer"
          />
          <AiOutlineDownload className=" hover:text-green-500 cursor-pointer" />
        </div>
      </div>
    ));

    if (this.state.file_names.length === 0) {
      Files = (
        <div className="flex flex-row w-full justify-center items-center py-2 border-t border-b font-thin bg-gray-50 text-gray-300">
          <span>Nothing To See Here!</span>
        </div>
      );
    }

    let Tags = [];
    let TagStep = [
      this.state.charge_deposit,
      this.state.charge_cleaning,
      this.state.charge_credit_fee,
    ];
    let TagLabel = ["Charge Deposit", " Charge Cleaning", "Charge Credit Fee"];

    for (let i = 0; i < TagStep.length; i++) {
      if (TagStep[i] === true) {
        Tags[i] = (
          <span className="px-3 mr-2 border border-green-300 rounded-full bg-green-100 text-green-700 text-xs whitespace-nowrap inline-flex mb-2 sm:mb-0">
            {TagLabel[i]}
          </span>
        );
      } else {
        Tags[i] = <></>;
      }
    }

    return (
      <div className=" bg-white font-display lg:rounded-tl-lg lg:mt-0 mt-16 pb-20 lg:pb-0  ">
        <EditCorporate
          toggle={this.state.editToggle}
          handleEditBarToggle={this.handleEditBarToggle}
          corporateData={this.state}
          handleFileRemove={this.handleRemoveFile}
          handleFileChange={this.handleFileChange}
          handleInputChange={this.handleInputChange}
          handleToggleChange={this.handleToggleChange}
        />

        {bgFocus}
        <BreadCrumb
          rootPage="Contacts > Corporate"
          subPage={this.state.corporation}
          sideButton=""
          buttonAction={""}
        />

        <div className=" fade-in  px-4 lg:px-10 max-w-screen-xl mx-auto border rounded-lg py-10 bg-white">
          {/* Heading Info Area  */}
          <div className="flex flex-row mb-0">
            {/* Left Side  */}
            <div className="flex flex-col w-1/2">
              <h1 className="text-2xl  pt-2 text-gray-700 font-black font-display flex flex-row  ">
                <span className="pr-3">{this.state.corporation} </span> {flag}
              </h1>

              <h2 className="  mb-1 text-md font-extralight text-gray-500 font-europe">
                {this.state.address}
              </h2>

              <h2 className="  mb-1 text-sm font-extralight text-gray-400 font-europe">
                Client Manager - {this.state.client_manager}
              </h2>
              <h2 className="  mb-1 text-sm font-extralight text-gray-400 font-europe">
                Require Payment:{" "}
                <span
                  className={
                    this.state.requires_initial_payment
                      ? "text-green-500"
                      : "text-red-500"
                  }
                >
                  {this.state.requires_initial_payment ? "Yes" : "No"}
                </span>
              </h2>
              <h2 className="  mb-1 text-sm font-extralight text-gray-400 font-europe">
                Expiry Policy - {this.state.expiry} Hours
              </h2>
            </div>

            {/* Right Side */}
            <div className="flex flex-row w-1/2 items-start justify-end">
              <EditOptionPanel
                handleEditBarToggle={this.handleEditBarToggle}
                parentData={this.state}
                panelType="Corporate"
                handleFlag={this.toggleFlag}
                handleState={this.handleToggleChange}
              />
            </div>
          </div>

          <div className="flex flex-row w-full justify-start mt-3 pl-1 flex-wrap ">
            {Tags}
          </div>

          <div className="mt-10 font-semibold text-gray-500">Contacts</div>
          <div className="grid grid-cols-1 md:grid-cols-3 w-full gap-3 my-2">
            {this.state.admin_contacts.map((admin) => (
              <ContactCardDisplay
                contactType="Admin"
                data={admin}
                corporation={this.state.corporation}
              />
            ))}
            {this.state.finance_contacts.map((finance) => (
              <ContactCardDisplay
                contactType="Finance"
                data={finance}
                corporation={this.state.corporation}
              />
            ))}
            {this.state.cleaning_contacts.map((cleaning) => (
              <ContactCardDisplay
                contactType="Cleaning"
                data={cleaning}
                corporation={this.state.corporation}
              />
            ))}
          </div>

          <div className="mt-8  text-gray-500">Financial Policies</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 text-xs text-gray-400 w-full gap-3 my-3">
            <div className="bg-white px-3 py-2 flex flex-col rounded-sm border">
              <span className="text-sm font-medium text-gray-700">
                Initial Payment Policy:
              </span>
              <span
                className={
                  this.state.requires_initial_payment
                    ? "text-red-500 font-semibold"
                    : "text-green-500 font-semibold"
                }
              >
                {this.state.requires_initial_payment
                  ? "Requires first rent before check-in"
                  : "No upfront payment required"}
              </span>
            </div>

            <div className="bg-white px-3 py-2 flex flex-col rounded-sm border">
              <span className="text-sm font-medium text-gray-700">
                Invoice Frequency
              </span>
              <span className="text-green-500 font-semibold">
                {INVOICE_FREQUENCY_LABELS[
                  this.state.invoicing_method as InvoiceFrequency
                ] || "N/A"}
              </span>
            </div>

            <div className="bg-white px-3 py-2 flex flex-col rounded-sm border">
              <span className="text-sm font-medium text-gray-700">
                Invoice Timing
              </span>
              <span className="text-green-500 font-semibold">
                {INVOICE_TIMING_LABELS[
                  this.state.payment_timing as InvoiceTiming
                ] || "N/A"}
              </span>
            </div>
          </div>

          <div className="mt-8  text-gray-500">Communication Policies</div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 text-xs text-gray-400 w-full gap-3 my-3">
            <div className="bg-gray-100 px-3 py-2 flex flex-col rounded-sm border">
              <span>Admin Communication Policy:</span>
              <span className="text-gray-700">{this.state.admin_com_pol}</span>
            </div>
            <div className="bg-gray-100 px-3 py-2 flex flex-col rounded-sm border">
              <span>Finance Communication Policy:</span>
              <span className="text-gray-700">{this.state.fin_com_pol}</span>
            </div>
            <div className="bg-gray-100 px-3 py-2 flex flex-col rounded-sm border">
              <span>Cleaning Communication Policy:</span>
              <span className="text-gray-700">{this.state.clean_com_pol}</span>
            </div>
            <div className="bg-gray-100 px-3 py-2 flex flex-col rounded-sm border">
              <span>Variable Communication Policy:</span>
              <span className="text-gray-700">
                {this.state.var_com_pol ? "True" : "False"}
              </span>
            </div>
          </div>

          <div className=" mt-3 flex flex-row flex-wrap justify-between text-xs text-gray-400 w-full gap-3 my-4">
            <span>
              Invoicing Method:{" "}
              <span className={"text-green-500"}>
                {this.state.invoicing_method}
              </span>
            </span>
            <span>
              Method of Payment:{" "}
              <span className="text-gray-700">{this.state.payment_type}</span>{" "}
            </span>
            <span>
              Notice To Vacate:{" "}
              <span className="text-gray-700">{this.state.ntv} </span>{" "}
            </span>
            <span>
              Payment Terms:{" "}
              <span className="text-gray-700">{this.state.payment_terms} </span>{" "}
            </span>
            <span>
              First/Last Policy:{" "}
              <span className="text-gray-700">{this.state.first_last}</span>{" "}
            </span>
          </div>

          <div className="mt-8 mb-2 text-lg text-gray-800">Files:</div>
          <div className="w-full">{Files}</div>

          <div className="mt-8 mb-2 text-lg text-gray-800">Notes:</div>
          <div className="border rounded-sm bg-gray-50 px-4 py-6 text-sm text-gray-500">
            <span>{this.state.corporate_notes}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(CorporateProfile);

export function ContactCardDisplay(props: any) {
  const name = `${props.data.first_name || ""} ${props.data.last_name || ""}`;
  const email = props.data.email;
  const phone = props.data.phone_number;
  const active = props.data.active;

  return (
    <div className="flex flex-col px-4 py-3 h-auto border-2 rounded-sm border-solid border-gray-100 ">
      <div className="flex flex-row w-full  items-center">
        <span className="font-medium text-gray-700 text-md pb-1 ">
          {props.contactType} -
          <span className=" transition-all duration-200 font-medium text-gray border-0 p-0  pl-1 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200">
            {name}
          </span>
        </span>
      </div>

      <span className="font-regular text-gray-400 text-xs">
        Corporation: {props.corporation}
      </span>
      <h3 className="mb-3 mt-2 font-light text-xs  text-gray-400 w-1/4">
        Status:
        <span
          className={`${
            active ? "bg-green-400 text-white" : "bg-red-400 text-white text-xs"
          } rounded-full ml-3 justify-center py-1 px-3 }`}
        >
          {active ? "Active" : "Inactive"}
        </span>
      </h3>
      <span className="font-regular text-gray-400 text-xs pb-4">
        Contact ID: {props.data.id}
      </span>
      <span className=" font-regular text-gray-400 text-xs transition-all duration-200 border-0 p-0 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200">
        {email}
      </span>
      <span className="flex flex-row items-center font-regular text-gray-400 text-xs ">
        <i className="text-gray-500 mr-1 ">
          <FaPhoneAlt />
        </i>
        <span className=" font-regular text-gray-400 text-xs transition-all duration-200 border-0 p-0 focus:ring-0 focus:border-blue hover:text-blue-300 placeholder-gray-200">
          {phone}
        </span>
      </span>
    </div>
  );
}
