import { useState, useEffect, ChangeEvent } from "react";
import ToggleSwitch from "../ToggleSwitch";
import { fetchGetJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import Multiselect from "multiselect-react-dropdown";
import { useNotification } from "../Notifications/NotificationProvider";

interface Props {
  sendAddOnsData: (key: string, value: any) => void;
  activeParkingSpots?: any;
  rate: number;
  turnoverCleaningFee: number;
}

const excludedAddOns = [
  { name: "Bi-Weekly Cleaning Fee" },
  { name: "Regular Cleaning Fee" },
  { name: "Parking Fee" },
];

type AddOnPayload = {
  id: number;
  name: string;
  price: number;
  invoicing_frequency: string;
  netsuite_internal_id: number;
  cleaning_frequency?: string;
};

let payload: AddOnPayload[] = [];

const AddOns = (props: Props) => {
  const [addOnsList, setAddOnsList] = useState([]);
  const [cleaningAddOn, setCleaningAddOn] = useState(null);
  const [parkingRequired, setParkingRequired] = useState(false);
  const [cleaningFrequency, setCleaningFrequency] = useState("none");
  const [petFee, setPetFee] = useState(0);
  const [hardCheckout, setHardCheckout] = useState(false);
  const [cleaningFee, setCleaningFee] = useState(0);
  const [activeParkingSpots, setActiveParkingSpots] = useState([]);
  const [parkingFee, setParkingFee] = useState(0);
  const [parkingNotes, setParkingNotes] = useState("");
  const [parkingSpotId, setParkingSpotId] = useState(-1);
  const [addOnsSelected, setAddOnsSelected] = useState([]);
  const [fees, setFees] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    const fetchAddOns = async () => {
      const addOnsFetch = await fetchGetJSON(
        `${Constants.API_PATH_GET_ADDONS}`
      );
      const bookingAddOnExclusionList = new Set([
        ...excludedAddOns.map((addOn) => addOn.name),
      ]);
      const filterAddOns = addOnsFetch.data.filter(
        (addOn: { name: string }) => !bookingAddOnExclusionList.has(addOn.name)
      );
      const filterCleaningAddOn = addOnsFetch.data.find(
        (addOn: { name: string }) => addOn.name === "Regular Cleaning Fee"
      );
      setAddOnsList(filterAddOns);
      setCleaningAddOn(filterCleaningAddOn);
    };
    fetchAddOns();
    setActiveParkingSpots(props.activeParkingSpots);
  }, []);

  const dispatch = useNotification();
  const NotificationHandler = (
    type: string,
    title: string,
    message: string
  ) => {
    dispatch({
      type: type.toUpperCase(),
      title: title,
      message: message,
    });
  };

  const handleInputChange = <
    T extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >(
    event: ChangeEvent<T>,
    setter: (value: any) => void,
    isNumber: boolean = false,
    key: string
  ) => {
    const value = isNumber ? Number(event.target.value) : event.target.value;
    if (isNumber) {
      if (Number(value) >= 0) {
        props.sendAddOnsData(key, value);
      }
    } else {
      props.sendAddOnsData(key, value);
    }
    setter(value);
  };

  const toggleParking = () => {
    setParkingRequired(!parkingRequired);
    if (parkingRequired) {
      setParkingFee(0);
      setParkingSpotId(-1);
      props.sendAddOnsData("parking_fee", 0);
      props.sendAddOnsData("parking_spot_id", -1);
    }
    props.sendAddOnsData("parkingRequired", !parkingRequired);
  };

  const toggleHardCheckout = () => {
    setHardCheckout(!hardCheckout);
    props.sendAddOnsData("hard_checkout", !hardCheckout);
  };

  const handleAddOnsSelected = (selectedList: any[]) => {
    // Convert selectedList into AddOnPayload format
    const formattedAddOns: AddOnPayload[] = selectedList.map((addOn) => ({
      id: Number(addOn.id),
      name: addOn.name || "",
      price: Number(addOn.price) || 0,
      invoicing_frequency: addOn.invoicing_frequency || "",
      netsuite_internal_id: Number(addOn.netsuite_internal_id) || -1,
    }));

    // Create a Set of selected IDs for quick lookup
    const selectedIds = new Set(formattedAddOns.map((addOn) => addOn.id));

    // Remove items from payload that are no longer in selectedList
    payload = payload.filter(
      (item) => selectedIds.has(item.id) || item.id === cleaningAddOn.id
    );

    // Add new items that are not already in payload
    formattedAddOns.forEach((newItem) => {
      if (!payload.some((existingItem) => existingItem.id === newItem.id)) {
        payload.push(newItem);
      }
    });

    // Update selected add-ons state
    setAddOnsSelected(selectedList);

    // Update fees state
    setFees((prevFees) => {
      const updatedFees = { ...prevFees };

      // Remove fees for items that are no longer in the payload
      Object.keys(updatedFees).forEach((id) => {
        if (!selectedIds.has(Number(id))) {
          delete updatedFees[id];
        }
      });

      return updatedFees;
    });

    props.sendAddOnsData("add_ons", payload);
  };

  const handleCleaningFrequencyChange = (frequency: string) => {
    setCleaningFrequency(frequency);

    const formattedCleaningAddOn = {
      id: cleaningAddOn.id,
      name: cleaningAddOn.name || "",
      price: Number(cleaningAddOn.price) || 0,
      invoicing_frequency: cleaningAddOn.invoicing_frequency || "",
      netsuite_internal_id: Number(cleaningAddOn.netsuite_internal_id) || -1,
      cleaning_frequency: frequency,
    };

    if (frequency === "none") {
      payload.filter((item) => item.id !== cleaningAddOn.id);
    } else {
      if (
        !payload.some((existingItem) => existingItem.id === cleaningAddOn.id)
      ) {
        payload.push(formattedCleaningAddOn);
      }
    }
    props.sendAddOnsData("cleaning_frequency", frequency);
  };

  const handleFees = (id: number, value: string) => {
    let payloadFee = parseFloat(value);
    setFees((prevFees) => ({
      ...prevFees,
      [id]: payloadFee || 0,
    }));
    if (parseFloat(value) < 0) {
      payloadFee = 0;
    }
    payload = payload.map((addOn) => {
      return addOn.id == Number(id)
        ? {
            ...addOn,
            price: payloadFee,
          }
        : addOn;
    });

    props.sendAddOnsData("add_ons", payload);
  };

  const getInvoicingFrequencyPlaceholder = (frequency: string) => {
    if (frequency === "one_time") {
      return "Enter one time fee";
    }
    if (frequency === "recurring") {
      return "Enter price per add on";
    }
    if (frequency === "prorated") {
      return "Enter nightly fee";
    }
    return "Enter fee";
  };

  const isRateNull = (rate: number) => {
    if (rate > 0) {
      return false;
    }
    return true;
  };

  return (
    <div
      className={`mb-5 p-5 border-2 border-gray-300 shadow-lg rounded-lg slide-in-blurred-top transition-all duration-600`}
    >
      <h1 className="text-3xl font-bold text-green-500">Add On's</h1>
      <div className="text-gray-600 text-md">
        Any add-ons created will be included in the{" "}
        <span className="font-bold text-red-500">FIRST RENT INVOICE</span>. If
        you need a separate invoice for add-ons, please add them after the
        booking is created.
      </div>
      {isRateNull(props.rate) && (
        <div className="text-red-500 text-2xl font-bold">
          Nightly rate must be greater than 0 to edit add-on fees
        </div>
      )}

      <Multiselect
        options={addOnsList}
        displayValue="name"
        placeholder="Search add on's"
        avoidHighlightFirstOption={true}
        selectedValues={addOnsSelected}
        onSelect={handleAddOnsSelected}
        onRemove={handleAddOnsSelected}
        className="border-blue-400 border rounded-md my-4"
      />

      <div className="space-y-5 w-3/5 flex-col">
        {addOnsSelected.map((addOn) => (
          <div
            key={addOn.id}
            className="flex items-center space-x-2 border-0 p-2 rounded-md"
          >
            <div className="transition-all duration-600 font-bold text-blue-500 text-sm w-2/5">
              {addOn.name}{" "}
              <span className="text-xs text-gray-500 font-normal">
                ({getInvoicingFrequencyPlaceholder(addOn.invoicing_frequency)})
              </span>
            </div>
            <div className="flex flex-row items-center space-x-1 w-1/2">
              <label className="text-gray-400 text-sm">$</label>
              <input
                type="number"
                placeholder={getInvoicingFrequencyPlaceholder(
                  addOn.invoicing_frequency
                )}
                value={fees[addOn.id] || ""}
                onChange={(e) => handleFees(addOn.id, e.target.value)}
                disabled={isRateNull(props.rate)}
                className={`text-sm transition-all duration-600 font-normal text-gray-600
                      mt-0 block w-1/2 px-0.5 border-0 border-b-2 border-gray-300
                      placeholder-gray-300 focus:ring-0 focus:border-gray-500 ${
                        isRateNull(props.rate)
                          ? "cursor-not-allowed bg-gray-100 text-gray-400"
                          : "text-gray-600"
                      }`}
              />
              {fees[addOn.id] < 0 && (
                <div className="text-sm text-red-500">
                  Fee cannot be negative
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div
        className="w-7/12 font-medium pl-3 py-3 text-gray-700 mt-5 rounded-sm
                border-gray-100 border-2"
      >
        <span>Cleaning Frequency -</span>
        <select
          id="cleaning_frequency"
          onChange={(e) => handleCleaningFrequencyChange(e.target.value)}
          value={cleaningFrequency}
          className=" space-x-64  whitespace-nowrap mt-0 py-0 pl-1 w-auto border-0 text-gray-500
                    border-gray-200 focus:ring-0 focus:border-black"
        >
          <option value="bi_weekly">Bi-Weekly</option>
          <option value="weekly">Weekly</option>
          <option value="none">Never</option>
        </select>
        {cleaningFrequency !== "none" && (
          <div className="flex flex-row items-center mt-4">
            <label className="transition-all duration-600 text-gray-500 text-sm w-1/3">
              Regular Cleaning Fee
            </label>
            <div className="flex flex-row items-center space-x-1">
              <label className="text-gray-400 text-sm">$</label>
              <input
                type="number"
                placeholder={getInvoicingFrequencyPlaceholder(
                  cleaningAddOn.invoicing_frequency
                )}
                value={fees[cleaningAddOn.id] || ""}
                onChange={(e) => handleFees(cleaningAddOn.id, e.target.value)}
                disabled={isRateNull(props.rate)}
                className={`text-sm transition-all duration-600 font-normal text-gray-600
                      mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-300
                      placeholder-gray-300 focus:ring-0 focus:border-gray-500 ${
                        isRateNull(props.rate)
                          ? "cursor-not-allowed bg-gray-100 text-gray-400"
                          : "text-gray-600"
                      }`}
              />
            </div>
            {fees[cleaningAddOn.id] < 0 && (
              <div className="text-sm text-red-500">Fee cannot be negative</div>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-row my-5 my-8 ml-2 w-4/5">
        <div className="flex flex-row items-center w-3/5">
          <span className=" transition-all duration-600 text-gray-500 text-sm w-1/4">
            Pet Fee
          </span>
          <div className="flex flex-row items-center space-x-1">
            <label className="text-gray-400 text-sm">$</label>
            <input
              id="pet_fee"
              onChange={(e) => handleInputChange(e, setPetFee, true, "pet_fee")}
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
              disabled={isRateNull(props.rate)}
              type="number"
              className={`text-sm transition-all duration-600 font-normal text-gray-600
                      mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-300
                      placeholder-gray-300 focus:ring-0 focus:border-gray-500 ${
                        isRateNull(props.rate)
                          ? "cursor-not-allowed bg-gray-100 text-gray-400"
                          : "text-gray-600"
                      }`}
              placeholder="Enter Fee"
            />
          </div>
          {petFee < 0 && (
            <div className="text-sm text-red-500">Fee cannot be negative</div>
          )}
        </div>
        <div className="flex flex-row items-center justify-between w-1/4">
          <div className="flex flex-row items-center justify-between">
            <span className=" transition-all duration-600 text-gray-500 text-sm mr-2">
              Hard Check Out
            </span>
            <ToggleSwitch
              id="hard_checkout"
              className="justify-center align-center"
              size="lg"
              onoff={hardCheckout}
              handleStateToggle={toggleHardCheckout}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row mt-5 ml-2 w-4/5">
        <div className="flex flex-row items-center w-3/5 ">
          <span className=" transition-all duration-600 text-gray-500 text-sm w-1/4">
            Turnover Cleaning Fee
          </span>
          <div className="flex flex-row items-center space-x-1">
            <label className="text-gray-400 text-sm">$</label>
            <input
              id="cleaning_fee"
              onChange={(e) =>
                handleInputChange(e, setCleaningFee, true, "cleaning_fee")
              }
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
              disabled={isRateNull(props.rate)}
              type="number"
              className={`text-sm transition-all duration-600 font-normal text-gray-600
                      mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-300
                      placeholder-gray-300 focus:ring-0 focus:border-gray-500 ${
                        isRateNull(props.rate)
                          ? "cursor-not-allowed bg-gray-100 text-gray-400"
                          : "text-gray-600"
                      }`}
              value={props.turnoverCleaningFee}
            />
          </div>
          {cleaningFee < 0 && (
            <div className="text-sm text-red-500">Fee cannot be negative</div>
          )}
        </div>

        <div className="flex flex-row items-center justify-between w-1/4 mt-2 ">
          <div className="flex flex-row items-center justify-between">
            <span className=" transition-all duration-600 text-gray-500 text-sm mr-2">
              Parking Required?
            </span>
            <ToggleSwitch
              className="justify-center align-center"
              size="lg"
              onoff={parkingRequired}
              handleStateToggle={toggleParking}
            />
          </div>
        </div>
      </div>

      {parkingRequired && (
        <div>
          <br />
          <hr />
          <div className="flex flex-col my-5">
            <h1 className="pl-2 mb-2">Parking Information: </h1>
            <div className="pl-2 flex flex-col w-52 mb-5">
              <label className="block  ">
                <label className="block  ">
                  <span className="text-green-400 text-sm">Parking Spots*</span>
                  <div className="w-full max-w-xs">
                    <select
                      id="parking_spot_id"
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          setParkingSpotId,
                          false,
                          "parking_spot_id"
                        )
                      }
                      multiple
                      className="block min-w-fit overflow-x-auto text-sm  mt-1 rounded-sm border-gray-100 border-2
                                  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    >
                      {activeParkingSpots.map((parkingSpot: any) => (
                        <option key={parkingSpot.id} value={parkingSpot.id}>
                          {" "}
                          {parkingSpot.parking_spot_location} |{" "}
                          {parkingSpot.parking_spot_level}{" "}
                        </option>
                      ))}
                    </select>
                  </div>
                </label>
              </label>
            </div>
            <div className="flex flex-col w-44 ml-2">
              <span className=" transition-all duration-600 text-green-400 text-sm mr-3">
                Parking Fee (Per Night)
              </span>
              <div className="flex flex-row items-center space-x-1">
                <label className="text-gray-400 text-sm">$</label>
                <input
                  id="parking_fee"
                  onChange={(e) =>
                    handleInputChange(e, setParkingFee, true, "parking_fee")
                  }
                  onWheel={(e) => {
                    e.currentTarget.blur();
                  }}
                  disabled={isRateNull(props.rate)}
                  type="number"
                  className={`text-sm transition-all duration-600 font-normal text-gray-600
                            mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-300 placeholder-gray-300
                            focus:ring-0 focus:border-gray-500 ${
                              isRateNull(props.rate)
                                ? "cursor-not-allowed bg-gray-100 text-gray-400"
                                : "text-gray-600"
                            }`}
                  placeholder="Enter Fee"
                />
              </div>
              {parkingFee < 0 && (
                <div className="text-sm text-red-500">
                  Fee cannot be negative
                </div>
              )}
            </div>
            <div className="flex flex-col w-7/12 mt-5 ml-2">
              <span className=" transition-all duration-600 text-green-400 text-sm w-1/3 mt-3">
                Parking Notes
              </span>
              <textarea
                id="parking_notes"
                className={`mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
                  isRateNull(props.rate)
                    ? "cursor-not-allowed bg-gray-100 text-gray-400"
                    : "text-gray-600"
                }`}
                rows={3}
                onChange={(e) =>
                  handleInputChange(e, setParkingNotes, false, "parking_notes")
                }
                disabled={isRateNull(props.rate)}
                value={parkingNotes}
              ></textarea>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddOns;
