import { Component } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import ToggleSwitch from "../ToggleSwitch";
import { IoIosArrowBack } from "react-icons/io";
import { formatFullDateString } from "../../utils/DateFormat";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import store from "../../redux/store";
import { Constants } from "../../api-requests/apiLinkConstants";

type Props = {
  ownerData: any;
  toggle: any;
  handleEditBarToggle: any;
  handleInputChange: any;
  handleToggleChange: any;
};

type EditUnitState = {
  id: number;
  owner_name: string;
  first_name: string;
  last_name: string;
  owner_email: string;
  owner_phone: string;
  owner_notes: string;
  chargeback: number;
  do_not_disturb: boolean;
};

class EditOwner extends Component<Props, EditUnitState> {
  constructor(props: any) {
    super(props);
    this.state = {
      id: -1,
      owner_name: this.props.ownerData.owner_name,
      first_name: this.props.ownerData.owner_name.split(" ")[0],
      last_name: this.props.ownerData.owner_name.split(" ")[1],
      owner_email: this.props.ownerData.owner_email,
      owner_phone: this.props.ownerData.owner_phone,
      owner_notes: this.props.ownerData.owner_notes,
      chargeback: this.props.ownerData.chargeBack,
      do_not_disturb: this.props.ownerData.do_not_disturb,
    };
  }

  //     handleCorpToggle = (event) => {
  //       event.preventDefault();
  //       if ( this.state.corporate === false ) {
  //          this.setState({corporate: true});
  //       }

  //       else {
  //          this.setState({corporate: false});
  //      }

  //     }

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  componentDidMount = async () => {
    let unitOwnerId = new URLSearchParams(window.location.search).get("id");
    let owner = await fetchGetJSON(
      `${Constants.API_PATH_OWNER_GET}${unitOwnerId}`
    );
    let ownerData = owner.data;
    this.setState({
      id: ownerData.id,
      owner_name: `${ownerData.first_name} ${ownerData.last_name}`,
      first_name: ownerData.first_name,
      last_name: ownerData.last_name,
      owner_email: ownerData.email,
      owner_phone: ownerData.phone_number,
      owner_notes: ownerData.notes,
      chargeback: ownerData.chargeback,
      do_not_disturb: ownerData.do_not_disturb,
    });
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = (function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    })();
    this.setState(stateObject);
  };

  handleClick = () => {
    if (this.state.owner_phone) {
      let value = "";
      //string with dashes removed
      for (var i = 0; i < this.state.owner_phone.length; i++) {
        if (this.state.owner_phone[i] !== "-") {
          value += this.state.owner_phone[i];
          console.log(value.length);
        }
      }
      // store to db with dashes for now
      // this.setState({owner_phone: value})

      //check if number is at max 10 digits
      if (value.trim().replaceAll(" ", "").length > 10) {
        console.log(value.trim().replaceAll(" ", "").length);
        alert("Please enter a valid phone number ");
        return;
      }
    }

    if (!this.state.owner_name.trim().split(" ")[1]) {
      alert(
        "Please enter a valid name for the owner in the format of first name then last name"
      );
      return;
    }
    let unitOwnerEdit = {
      id: this.state.id,
      fields: {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        phone_number: this.state.owner_phone,
        email: this.state.owner_email.trim(),
        owner_notes: this.state.owner_notes,
        chargeback: this.state.chargeback,
        do_not_disturb: this.state.do_not_disturb,
      },
    };

    fetchRequestJSON("PUT", Constants.API_PATH_OWNER_EDIT, unitOwnerEdit).then(
      function (result) {
        window.location.reload();
      }
    );
  };

  render() {
    let toggleEdit = this.props.toggle;
    let ProfileType = "Edit Owner";
    let editclassName =
      "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide ";
    let editType = "booking";
    let content;
    let inactive = "";
    let tabIndex;
    let ManagedModel = "";
    let RentalModel = "";
    let modelEntry = [];

    if (toggleEdit == true) {
      editclassName = " z-50 opacity-100 translate-x-0 lg:max-w-lg w-full  ";
    }

    content = (
      <form className="  flex flex-col pl-6 pr-12 py-4">
        <span className="text-gray-600 text-md font-bold mb-4 mt-2">
          General Info
        </span>

        {/* <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Name</span>
          <input
            id="owner_name"
            onChange={this.handleInputChange}
            value={this.state.owner_name}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label> */}

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">First Name</span>
          <input
            id="first_name"
            onChange={this.handleInputChange}
            value={this.state.first_name}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Last Name</span>
          <input
            id="last_name"
            onChange={this.handleInputChange}
            value={this.state.last_name}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Owner Email</span>
          <input
            id="owner_email"
            onChange={this.handleInputChange}
            value={this.state.owner_email}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Owner Phone</span>
          <input
            id="owner_phone"
            onChange={this.handleInputChange}
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            value={this.state.owner_phone}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Chargeback Deductible</span>
          <input
            id="chargeback"
            onChange={this.handleInputChange}
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            value={this.state.chargeback}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder="$"
          />
        </label>

        <label className="block pl-3 mb-5">
          <div className="flex flex-row items-center">
            <span
              className={
                " transition-all duration-600 text-gray-600 text-sm mr-3"
              }
            >
              Do Not Disturb
            </span>

            <ToggleSwitch
              id="do_not_disturb"
              handleStateToggle={this.handleToggleChange}
              className="justify-center align-center"
              size="lg"
              onoff={this.state.do_not_disturb}
            />
          </div>
        </label>

        <label className="block pl-3 mb-5 mt-2">
          <span className="text-gray-400 text-xs">Additional Notes</span>
          <textarea
            id="owner_notes"
            onChange={this.handleInputChange}
            value={this.state.owner_notes}
            className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            rows={3}
          ></textarea>
        </label>
      </form>
    );

    return (
      <div
        className={
          editclassName +
          " bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300  "
        }
      >
        {/* Top Title Bar */}
        <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
          <button
            onClick={this.props.handleEditBarToggle}
            className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
          >
            <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
          </button>

          <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
            <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
          </button>
        </div>
        <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
          <span className=" font-black text-xl text-gray-700">
            {ProfileType}{" "}
          </span>
        </div>

        {content}

        {/* Submit Logic Below */}
        <div className="h-24 flex flex-row ">
          <button
            onClick={() => {
              this.handleClick();
              this.props.handleEditBarToggle();
            }}
            className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default EditOwner;

export function inputISO(date: Date) {
  let year = date.getFullYear().toString();
  let month = date.getMonth();
  let dt = date.getDate();
  let day = "";
  let month2 = "";

  if (dt < 10) {
    day = "0" + dt;
  } else {
    day = dt.toString();
  }
  if (month < 10) {
    month2 = "0" + month;
  } else {
    month2 = month.toString();
  }

  return year + "-" + month2 + "-" + day;
}
