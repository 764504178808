export type Booking = {
  id: number;
  check_in?: Date;
  check_out?: Date;
  unit_id?: number;
  tenant_id?: number;
  booking_type?: string;
  monthly_rate?: number;
  parking_id?: number;
  booking_notes?: string;
  corporate_id?: number;
  corporate_admin_id?: number;
  corporate_finance_id?: number;
  corporate_cleaning_id?: number;
  cleaning_frequency?: string;
  confirmed?: boolean;
  flight?: string;
  next_booking_id?: number;
  previous_booking_id?: number;
  flagged?: boolean;
  flag_reason?: string;
  local?: boolean;
  created_by?: number;
  deposit_expiry?: number;
  parking_requested?: boolean;
  pets?: boolean;
  check_in_notes?: string;
  confirmed_check_out?: boolean;
  check_out_notes?: string;
  fobs?: number;
  keys?: number;
  mail_keys?: number;
  parking_fobs?: number;
  agreement_id?: number;
  agreement_conf?: string;
  cancelled?: boolean;
  original_booking_id?: boolean;
  created_at?: Date;
  updated_at?: Date;
  pet_fee?: number;
  cleaning_fee?: number;
  parking_fee?: number;
  deposit_amount?: number;
  corporate_deduction?: number;
  other_tenants: any[];
  supplier?: string;
  hard_checkout?: boolean;
  reviewed_by?: string;
  light?: string;
  cleaning_confirm: boolean;
  payment_confirm: boolean;
  keys_confirm: boolean;
  keyCode?: number;
  expiry?: boolean;
  expiry_job_id?: number;
  expiry_reminder_job_id?: number;
  release: boolean;
  ru_reservation_id: number;
};

export type Corporate = {
  id: number;
  created_at?: string;
  updated_at?: string;
  corporate_name?: string;
  method_of_payment?: string;
  first_last_policy?: string;
  deposit_policy?: boolean;
  cleaning_fee?: boolean;
  credit_fee?: boolean;
  communication_type?: string;
  active?: boolean;
  invoicing_policy?: string;
  notice_to_vacate?: number;
  payment_terms?: number;
  notes?: string;
  address?: string;
  city?: string;
  province_state?: string;
  postal_code?: string;
  country?: string;
  invoicing_method?: string;
  cleaning_policy?: string;
  cleaning_communication_policy?: string;
  admin_communication_policy?: string;
  finance_communication_policy?: string;
  variable_communication_policy?: string;
  client_manager?: string;
  expiry?: number;
  requires_initial_payment: boolean;
};

export type Cleaning = {
  id: number;
  created_at?: string;
  updated_at?: string;
  unit_id?: number;
  booking_id?: number;
  cleaning_date?: Date;
  confirmed?: boolean;
  notes?: string;
  title?: string;
  cleaning_type?: string;
  movable?: boolean;

  unit?: Unit;
};

export type CorporateContact = {
  id: number;
  created_at?: string;
  updated_at?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  email?: string;
  corporate_id?: number;
  admin?: boolean;
  finance?: boolean;
  cleaning?: boolean;
  primary?: boolean;
};

export type Extension = {
  id: number;
  created_at?: string;
  updated_at?: string;
  extend_until?: string;
  confirmed?: boolean;
  accepted?: boolean;
  start?: string;
  date_confirmed?: string;
  overpayment_invoices?: string;
  booking_id?: number;
};

export type Invoice = {
  id: number;
  created_at?: string;
  updated_at?: string;
  booking_id?: number;
  due?: string;
  payment_method_invoice?: string;
  paid?: boolean;
  rent?: boolean;
  end?: string;
  date_paid?: string;
  marked_by?: string;
  confirmed_by?: string;
  confirmation?: string;
  refunded?: boolean;
  link?: string;
  bank_date?: string;
  user_refunded?: number;
  first_rent?: boolean;
  negative_invoice?: boolean;
  overpayment?: boolean;
  unmarked_by?: number;
  unmark_confirmed_by?: number;
  unmark_reason?: string;
  modified_due_date?: string;
  modified_end_date?: string;
  invoice_types?: string;
  invoice_notes?: string;
  start_date?: string;
};

export type Parking = {
  id: number;
  created_at?: string;
  updated_at?: string;
  start_date?: string;
  end_date?: string;
  cancelled?: boolean;
  parking_notes?: string;
  booking_id?: number;
  parking_spot_id?: number;
  confirmed?: boolean;
  tenant_id?: number;
};

export type Tenant = {
  id: number;
  created_at?: string;
  updated_at?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
};

export type Unit = {
  id: number;
  created_at?: string;
  updated_at?: string;
  location?: string;
  suite_number?: string;
  suite_name?: string;
  square_feet?: number;
  bedrooms?: number;
  balcony?: boolean;
  bathrooms?: number;
  notes?: string;
  type?: string;
  active?: boolean;
  suite_type?: string;
  building_id?: number;
  unit_owner_id?: number;
  sofa_bed?: boolean;
  bunk_bed?: boolean;
  bed_configuration?: string;
  special?: boolean;
  deactivation_date?: Date;
  isQuadable?: boolean;
  quad_status?: boolean;
  super_quad?: boolean;
  super_quad_notes?: string;
  super_quad_bunk?: boolean;
};

export type OccupancyPercentage = {
  month: string;
  retail: number;
  corporate: number;
  total: number;
};

export type SalesOccupancyResponse = {
  status: string;
  data: {
    checkinsToday: number;
    checkoutsToday: number;
    vacantsToday: number;
    occupancyPercentages: OccupancyPercentage[];
  };
};

export type messagePayload = {
  type: string;
  title: string;
  message: string;
};

export type BookingPortalInfoResponse = {
  id: number;
  check_in: string;
  check_out: string;
  parking_requested: boolean;
  keyCode: string;
  monthly_rate: number;
  unit_id: number;
  tenant: Tenant;
};

export type TenantPortalInfoResponse = {
  id: number;
  first_name: string;
  last_name: string;
};

export type CleaningPortalInfoResponse = {
  cleaning_date: string;
};

export type UnitPortalInfoResponse = {
  id: number;
  suite_name: string;
  suite_number: string; //note: how the payload is returned
  suite_type: string;
  building: BuildingPortalInfoResponse;
};

export type BuildingPortalInfoResponse = {
  id: number;
  address: string;
  amenities: string;
  special_instructions: string;
  building_name: string;
};

export type ParkingInfo = {
  id: number;
  parking_spot: ParkingSpotsPortalResponse;
};

export type ParkingSpotsPortalResponse = {
  parking_spot_level: string;
  parking_spot_location: string;
  building_id: number;
};

export type ParkingInformationResponse = {
  ParkingInfo: ParkingInfo;
  ParkingInfoBuilding: ParkingInfoBuilding;
};

export type ParkingInfoBuilding = {
  address: string;
  building_name: string;
};

export type BookingPortalResponse = {
  status: string;
  data: BookingPortalData;
};

export type BookingPortalData = {
  bookingData: BookingPortalInfoResponse;
  cleaning: CleaningPortalInfoResponse[];
  unitInfo: UnitPortalInfoResponse;
  parkingInformation: ParkingInformationResponse;
};

export type RetrospectiveInvoiceBreakdown = {
  invoice_id: number;
  invoice_Lines_id: number;
  start_date: string;
  end: string;
  due: string;
  cost: number;
  invoice_length: number;
  invoice_daily_rate: number;
  line_type: string;
  invoice_types: string;
  entry: string;
};

export type AddOns = {
  id: number;
  created_at?: string;
  updated_at?: string;
  name?: string;
  description?: string;
  invoicing_frequency?: string;
  netsuite_internal_id?: number;
  cleaning_frequency?: string;
  selected_date?: string;
  price?: number;
};

export type EditAddOnsPayload = {
  id: number;
  add_ons: AddOns[];
};

export interface SalesOrderResponseFromBookingID {
  status: string;
  data: SalesOrderDataForBookingID[];
}

export interface SalesOrderDataForBookingID {
  salesOrderId: string;
  customerName: string;
  documentNumber: string;
  salesOrderItems: SalesOrderItemForBookingID[];
  invoiceItems: InvoiceItemForBookingID[];
}

export interface SalesOrderItemForBookingID {
  amount: number;
  start_date: string; // ISO date format
  end_date: string; // ISO date format
  quantity: number;
  rate: number;
  description: string;
  billingDate: string | null; // Can be null for immediate billing
  line: number;
  entity: string;
  customerName: string | null;
  associated_invoice_id: number | null;
  associated_invoice_link: string | null;
}

export interface InvoiceItemForBookingID {
  id: string;
  cost: string; // Use string if the cost is a string; otherwise, change to number
  paid: boolean;
  created_at: string; // ISO date format
  updated_at: string; // ISO date format
  invoiceLink: string;
  start_date: string;
  end_date: string;
  items: InvoiceItems[];
  credited: boolean;
  isTaxable: boolean;
  documentNumber: string;
}

interface InvoiceItems {
  description: string;
  quantity: number;
  amount: number;
  rate: number;
  id: number;
}
let AppStage = process.env.REACT_APP_AWS_API_GATEWAY_LINK.split("/")
  .pop()
  .toLowerCase();
export default AppStage;
