import { useEffect, useState } from "react";
import { Backdrop, Modal } from "@mui/material";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { useNotification } from "../Notifications/NotificationProvider";
import ToggleSwitch from "../ToggleSwitch";
import { RiQuestionFill } from "react-icons/ri";
import { AddOns, EditAddOnsPayload } from "../../utils/Types";

interface Props {
  open: boolean;
  closeModal: any;
  hasAddOns: any;
  handleAddOnsData: any;
  bookingAddOns: any[];
  bookingId: string;
  cleaningFrequency: string;
}

const excludedAddOns = [
  { name: "Bi-Weekly Cleaning Fee" },
  { name: "Parking Fee" },
];

const AddOnsModal = (props: Props) => {
  const [addOnsList, setAddOnsList] = useState([]);
  const [selectedAddOn, setSelectedAddOn] = useState(null);
  const [fee, setFee] = useState(0);
  const [bookingAddOns, setBookingAddOns] = useState([]);
  const [fullBookingToggle, setFullBookingToggle] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [isCurrAddOnNegative, setIsCurrAddOnNegative] = useState(false);
  const [isNewAddOnNegative, setIsNewAddOnNegative] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [showDescription, setShowDescription] = useState<{
    [key: number]: boolean;
  }>({});
  const [disabledButtons, setDisabledButtons] = useState<{
    [key: number]: boolean;
  }>({});
  const [cleaningFrequency, setCleaningFrequency] = useState("none");
  const [isSubmitValid, setIsSubmitValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [originalPrices, setOriginalPrices] = useState<{
    [key: number]: Number;
  }>({});

  let payload: EditAddOnsPayload = {
    id: Number(props.bookingId),
    add_ons: [
      {
        id: 0,
        price: 0,
        name: "",
        netsuite_internal_id: -1,
        invoicing_frequency: "",
        cleaning_frequency: "",
        selected_date: "",
      },
    ],
  };

  useEffect(() => {
    const fetchAddOns = async () => {
      const addOnsFetch = await fetchGetJSON(
        `${Constants.API_PATH_GET_ADDONS}`
      );

      const bookingAddOnExclusionList = new Set([
        ...excludedAddOns.map((addOn) => addOn.name),
        // ...props.bookingAddOns.map((addOn) => addOn.id), can uncomment if we want to limit the add-on options to show only new add-ons
      ]);
      const filterAddOns = addOnsFetch.data.filter(
        (addOn: { name: string }) => !bookingAddOnExclusionList.has(addOn.name)
      );
      setAddOnsList(filterAddOns);
    };
    fetchAddOns();
    setBookingAddOns(props.bookingAddOns);
    setCleaningFrequency(props.cleaningFrequency);
    const initialPrices: { [key: number]: number } = {};
    props.bookingAddOns.forEach((addOn) => {
      initialPrices[addOn.id] = addOn.BookingsAddOns[0].custom_price;
    });
    setOriginalPrices(initialPrices);
  }, []);

  //Notification Handler Logic:
  const dispatch = useNotification();

  const NotificationHandler = (
    type: string,
    title: string,
    message: string
  ) => {
    dispatch({
      type: type.toUpperCase(),
      title: title,
      message: message,
    });
  };

  const removeEmptyStrings = (obj: any) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== "")
    );
  };

  const submitEditAddOns = async () => {
    if (selectedAddOn) {
      const isRegularCleaningFee =
        selectedAddOn.name === "Regular Cleaning Fee";

      if (
        (isRegularCleaningFee && fee >= 0) ||
        (!isRegularCleaningFee && fee > 0)
      ) {
        payload.add_ons[0].id = selectedAddOn.id;
        payload.add_ons[0].name = selectedAddOn.name;
        payload.add_ons[0].invoicing_frequency =
          selectedAddOn.invoicing_frequency;
        payload.add_ons[0].netsuite_internal_id =
          selectedAddOn.netsuite_internal_id;
        payload.add_ons[0].price = Number(fee);
        if (cleaningFrequency === "" && isRegularCleaningFee) {
          setErrorMessage("Frequency must be selected");
          setIsSubmitValid(false);
          return;
        }
        if (selectedDate === "") {
          if (selectedAddOn.invoicing_frequency && !fullBookingToggle) {
            setErrorMessage("Please select a date");
            setIsSubmitValid(false);
            return;
          }
        }
        payload.add_ons[0].selected_date = selectedDate;
        payload.add_ons[0].cleaning_frequency = cleaningFrequency;
        payload.add_ons = payload.add_ons.map(
          (addOn) => removeEmptyStrings(addOn) as AddOns
        );
        setIsSubmitValid(true);
        const addOnPayloadResponse = await fetchRequestJSON(
          "POST",
          Constants.API_PATH_ADD_TO_BOOKING_CREATE,
          payload
        );
        console.log(addOnPayloadResponse);
        if (addOnPayloadResponse.status !== "Success") {
          NotificationHandler(
            "Error",
            "Error",
            `${addOnPayloadResponse.message}`
          );
          props.closeModal();
          return;
        } else {
          NotificationHandler(
            "Success",
            "Success",
            "Add-Ons Successfully Added to Booking"
          );
          props.handleAddOnsData(addOnPayloadResponse.data);
          props.closeModal();
          window.location.reload();
          return;
        }
      } else {
        setErrorMessage("Fee is invalid. Please set fee to a valid value");
        setIsSubmitValid(false);
        return;
      }
    } else {
      setErrorMessage("Please select an add-on");
      setIsSubmitValid(false);
      return;
    }
  };

  const resetState = () => {
    setFullBookingToggle(true);
    setSelectedDate("");
    setCleaningFrequency("");
    setErrorMessage("");
    setIsSubmitValid(true);
  };

  const handleAddOnsChange = (e: any) => {
    resetState();
    const selectedName = e.target.value;
    const selectedAddOn = addOnsList.find(
      (addOn) => addOn.name === selectedName
    );
    if (selectedAddOn) {
      setSelectedAddOn(selectedAddOn);
    } else {
      setSelectedAddOn(null);
    }
  };

  const handleFees = (e: any) => {
    if (e.target.value >= 0) {
      setFee(e.target.value);
      setIsNewAddOnNegative(false);
    } else {
      NotificationHandler(
        "Error",
        "Error",
        "Fee cannot be set to a negative number"
      );
      setIsNewAddOnNegative(true);
    }
  };

  const handlePrice = (e: any, addOnId: number) => {
    const newPrice = Number(e.target.value);

    if (newPrice >= 0) {
      setBookingAddOns((prev) =>
        prev.map((addOn) =>
          addOn.id === addOnId
            ? {
                ...addOn,
                BookingsAddOns: addOn.BookingsAddOns.map((booking: any) => ({
                  ...booking,
                  custom_price: newPrice,
                })),
              }
            : addOn
        )
      );
      setIsCurrAddOnNegative(false);
    } else {
      NotificationHandler(
        "Error",
        "Error",
        "New price cannot be set to a negative number"
      );
      setIsCurrAddOnNegative(true);
    }
  };

  const handleFullBookingToggle = () => {
    setFullBookingToggle(!fullBookingToggle);
    setSelectedDate("");
  };

  const isProrated = (frequency: string) => {
    if (frequency === "prorated") {
      return true;
    }
    return false;
  };

  const isCleaning = (cleaning: string) => {
    if (cleaning === "Regular Cleaning Fee") {
      return true;
    }
    return false;
  };

  const getTooltipText = () => {
    switch (selectedAddOn.invoicing_frequency) {
      case "prorated":
        return "This add-on will be charged nightly";
      case "recurring":
        return "This add-on will be charged every month";
      case "one_time":
        return "This add-on will be charged once";
      default:
        return "This is your invoicing frequency information";
    }
  };

  const handleCleaningFrequencyChange = (frequency: string) => {
    setCleaningFrequency(frequency);
  };

  const handleMouseEnter = (id: number) => {
    setShowDescription((prev) => ({ ...prev, [id]: true }));
  };

  const handleMouseLeave = (id: number) => {
    setShowDescription((prev) => ({ ...prev, [id]: false }));
  };

  const requestEdit = async (id: number) => {
    const requestEditPayload: {
      id: number;
      add_ons: { id: number; name: string; price: number }[];
    } = {
      id: 0,
      add_ons: [],
    };
    bookingAddOns.forEach((addOn) => {
      if (addOn.id === id) {
        requestEditPayload.id = addOn.BookingsAddOns[0].booking_id;
        requestEditPayload.add_ons.push({
          id: addOn.BookingsAddOns[0].add_on_id,
          name: addOn.name,
          price: Number(addOn.BookingsAddOns[0].custom_price),
        });
      }
    });

    const req = await fetchRequestJSON(
      "POST",
      Constants.API_PATH_ADDONS_REQUEST_EDIT,
      requestEditPayload
    );
    console.log(req);

    if (req.status !== "Success") {
      NotificationHandler("Error", "Error", req.data);
      return;
    }

    setDisabledButtons((prev) => ({
      ...prev,
      [id]: true,
    }));
    NotificationHandler("Success", "Success", "Requested to edit add-on");
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={props.open}
      onClose={() => props.closeModal()}
    >
      <div className=" appear bg-white shadow-lg rounded-lg max-h-[80vh] overflow-y-auto w-3/4 border-4 border-gray-600 py-8 px-10 mx-auto flex flex-col align-self-center">
        <span className="text-xl font-bold text-green-600 text-left pb-4">
          Edit Add-Ons
        </span>
        <label className="mb-5 ">
          <span className="text-gray-400 text-xs">New Add-Ons</span>
          <select
            id="addOns"
            onChange={handleAddOnsChange}
            value={selectedAddOn?.name || ""}
            className="mt-0 py-0.5 block w-1/2 px-1 border-0 border-b-2 appearance-none border-gray-200 focus:ring-0 focus:border-black"
            style={{ minWidth: "fit-content" }}
            placeholder=""
          >
            <option value="" className="text-gray-600 text-sm" hidden>
              Select an Add-On
            </option>
            <option value="" className="text-gray-600 text-sm">
              None
            </option>
            {addOnsList.map((addOn: { name: string }) => (
              <option key={addOn.name} value={addOn.name}>
                {addOn.name}
              </option>
            ))}
          </select>
        </label>
        <div className="w-full flex mx-auto flex-col">
          {selectedAddOn ? (
            <div className="flex flex-col border-2 rounded-md">
              <div className="flex items-start space-x-5 p-2">
                <span className="transition-all duration-600 text-green-500 text-md pl-4 pr-5 font-bold border-r-2">
                  {selectedAddOn.name}
                </span>
                <span className="transition-all duration-600 text-gray-500 text-sm mr-2">
                  {selectedAddOn.description}
                </span>
              </div>
              <hr />
              <div className="flex items-center space-x-8 p-2 mx-4 my-2">
                <div className="flex flex-col space-y-1 items-start">
                  <label className="transition-all font-normal duration-600 text-gray-400 text-xs">
                    Enter Fee ($):
                  </label>
                  <input
                    type="number"
                    min={0}
                    placeholder="Enter Fee ($)"
                    onChange={handleFees}
                    className="text-sm transition-all duration-600 font-normal text-gray-600
                        mt-0 block w-full px-0.5 border-1 border-gray-200 rounded-md
                        placeholder-gray-300 focus:ring-0 focus:border-gray-500"
                  />
                  {isNewAddOnNegative && (
                    <label className="font-bold text-red-500 text-xs">
                      Fee cannot be negative
                    </label>
                  )}
                </div>
                <div className="flex flex-col space-y-1 items-center px-5">
                  <label className="transition-all font-normal duration-600 text-gray-400 text-xs">
                    Invoicing Frequency:
                  </label>
                  <div className="transition-all duration-600 text-gray-500 text-sm w-2/3 flex flex-row space-w-1 items-center">
                    {selectedAddOn.invoicing_frequency}
                    <div
                      onMouseEnter={() => setShowToolTip(true)}
                      onMouseLeave={() => setShowToolTip(false)}
                    >
                      <RiQuestionFill className="text-gray-500 hover:text-gray-600 ml-1" />
                      {showToolTip && (
                        <div
                          className="fixed -top-8 w-max -translate-x-1/2 whitespace-nowrap rounded-md bg-gray-800 
                        px-2 py-1 my-2 text-xs text-white opacity-0 shadow-lg transition-opacity duration-400
                        group-hover:opacity-100 z-[99999] block !opacity-100 !visible"
                        >
                          {getTooltipText()}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {isProrated(selectedAddOn.invoicing_frequency) && (
                  <div className="flex flex-row space-x-2 px-4 items-start">
                    <span className=" transition-all duration-600 text-gray-400 text-sm">
                      Full Booking?
                    </span>
                    <ToggleSwitch
                      id="fullBooking"
                      className="justify-center align-center"
                      size="lg"
                      onoff={fullBookingToggle}
                      handleStateToggle={handleFullBookingToggle}
                    />
                  </div>
                )}
                {!fullBookingToggle &&
                  isProrated(selectedAddOn.invoicing_frequency) && (
                    <div className="flex flex-col space-y-1 items-start justify-end pl-2">
                      <label className="transition-all font-normal duration-600 text-gray-400 text-xs">
                        Invoice Date:
                      </label>
                      <input
                        id="selectedDate"
                        onChange={(date) => {
                          setSelectedDate(date.target.value);
                        }}
                        value={selectedDate}
                        type="date"
                        className="mt-0 text-gray-400 py-1 block w-full px-0.5 border border-gray-200 focus:ring-0 focus:border-black"
                        placeholder=""
                      />
                    </div>
                  )}
                {isCleaning(selectedAddOn.name) && (
                  <div className="flex flex-row space-x-2 px-4">
                    <span className=" transition-all duration-600 text-gray-500 text-sm">
                      Cleaning Frequency?
                    </span>
                    <select
                      id="cleaning_frequency"
                      onChange={(e) =>
                        handleCleaningFrequencyChange(e.target.value)
                      }
                      value={cleaningFrequency}
                      className=" space-x-64  whitespace-nowrap mt-0 py-0 pl-1 w-auto border-1 text-gray-500
                    border-gray-200 focus:ring-0 focus:border-black"
                    >
                      <option value="" hidden>
                        Select Frequency
                      </option>
                      <option value="bi_weekly">Bi-Weekly</option>
                      <option value="weekly">Weekly</option>
                    </select>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="text-gray-400 text-center py-2 bg-gray-100 rounded-md">
              <i>Select an add-on to get started</i>
            </div>
          )}
          <br />
          <hr />
        </div>
        <div className="w-full flex flex-col mx-auto my-3">
          <div className="mt-5 mb-3 text-gray-600 font-bold">
            Current Add-Ons:
          </div>
          {isCurrAddOnNegative && (
            <label className="font-bold text-red-500 text-xs">
              Fee cannot be negative
            </label>
          )}
          {bookingAddOns.length === 0 ? (
            <div className="text-gray-400 text-center py-2 bg-gray-100 rounded-md">
              <i>No add-ons associated with this booking</i>
            </div>
          ) : (
            <div className="border-4 rounded-md">
              {bookingAddOns.map((addOn) => (
                <div
                  key={addOn.id}
                  className="flex items-center justify-between space-x-5 p-2 border-y-2"
                >
                  <div className="transition-all duration-600 text-gray-500 text-sm flex flex-row items-center w-1/4">
                    {addOn.name}
                    <div
                      onMouseEnter={() => handleMouseEnter(addOn.id)}
                      onMouseLeave={() => handleMouseLeave(addOn.id)}
                      className="p-1"
                    >
                      <RiQuestionFill className="text-gray-500 hover:text-gray-600" />
                      {showDescription[addOn.id] && (
                        <div
                          className="fixed -top-8 w-max max-w-[300px] -translate-x-[20%] rounded-md bg-gray-800 
                        px-2 py-1 my-2 text-xs text-white opacity-0 shadow-lg transition-opacity duration-400
                        group-hover:opacity-100 z-[99999] block !opacity-100 !visible break-words"
                        >
                          {addOn.description}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row space-y-1 items-center w-1/4">
                    <span className="text-gray-500 text-sm">Price: $</span>
                    <input
                      key={addOn.id}
                      type="number"
                      min={0}
                      placeholder={originalPrices[addOn.id].toString()}
                      onChange={(e) => handlePrice(e, addOn.id)}
                      className={`text-sm transition-all duration-600 font-normal text-gray-600
                        mt-0 block w-1/3 px-0.5 rounded-md border-1 ml-1
                        placeholder-gray-500 focus:ring-1 focus:border-gray-500 ${
                          disabledButtons[addOn.id] &&
                          "text-green-500 cursor-not-allowed"
                        }`}
                      disabled={disabledButtons[addOn.id]}
                    />
                  </div>
                  {isCleaning(addOn.name) && (
                    <div className="flex flex-row space-x-2 px-4 items-start w-1/4">
                      <span className=" transition-all duration-600 text-gray-500 text-sm">
                        Cleaning Frequency?
                      </span>
                      <select
                        id="cleaning_frequency"
                        onChange={(e) =>
                          handleCleaningFrequencyChange(e.target.value)
                        }
                        value={cleaningFrequency}
                        className=" space-x-64  whitespace-nowrap mt-0 py-0 pl-1 w-auto border-1 text-gray-500
                    border-gray-200 focus:ring-0 focus:border-black"
                      >
                        <option value="bi_weekly">Bi-Weekly</option>
                        <option value="weekly">Weekly</option>
                        <option value="none">Never</option>
                      </select>
                    </div>
                  )}
                  <div className="ml-auto w-1/4 text-center">
                    <button
                      key={addOn.id}
                      className={`text-white rounded-lg p-2 w-3/5 ${
                        disabledButtons[addOn.id]
                          ? "bg-gray-400 cursor-not-allowed"
                          : "bg-blue-500 hover:bg-blue-600 active:bg-blue-700 shadow-lg"
                      }`}
                      onClick={() => requestEdit(addOn.id)}
                      disabled={disabledButtons[addOn.id]}
                    >
                      {disabledButtons[addOn.id] ? (
                        <span>
                          <i>Requested</i>
                        </span>
                      ) : (
                        <span>Request to Edit</span>
                      )}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="mx-auto mt-6 mb-auto flex space-y-2 flex-col items-center">
          <button
            onClick={() => submitEditAddOns()}
            className="transition duration-200 w-36 bg-green-400 hover:bg-green-500 active:bg-green-600 px-5 py-2 rounded-md text-white shadow-lg text-lg"
          >
            Submit
          </button>
          {!isSubmitValid && (
            <div className="text-red-500 text-sm">{errorMessage}</div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddOnsModal;
