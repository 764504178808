import React, { Component, useEffect, useState } from "react";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import ToggleSwitch from "../ToggleSwitch";
import { Link, withRouter, useHistory, useRouteMatch } from "react-router-dom";
import DatePicker from "../DatePicker";
import { formatFullDateString } from "../../utils/DateFormat";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { type } from "os";
import Modal from "../Modal";
import { withHooksHOC } from "../../components/Notifications/NotificationProvider";
import { ZeroMonthlyFeeModal } from "./ZeroMonthlyFeeModal";
import ConfirmationModal from "../ConfirmationModal";

interface Props {
  dayCount: number;
  data: any;
  ErrorNote: any;
  openHighRisk: any;
}

interface SummaryInfoState {
  booking_nights: number;
  disabled: boolean;
}

class BookingSummary extends Component<Props, SummaryInfoState> {
  constructor(props: any) {
    super(props);
    this.state = {
      booking_nights: this.props.dayCount,
      disabled: false,
    };
  }

  getPricingFrequency() {
    if (this.props.data.pricing_type == "monthly") {
      return "Month";
    } else if (this.props.data.pricing_type == "nightly") {
      return "Night";
    } else if (this.props.data.pricing_type == "thirty_day") {
      return "30 Day";
    } else {
      return "";
    }
  }

  getDayCount() {
    if (Number.isNaN(this.props.dayCount)) {
      return -1;
    } else {
      return this.props.dayCount;
    }
  }

  getDayCountStyle(dayCount: number) {
    if (dayCount < 0) {
      return "hidden";
    } else {
      return "";
    }
  }

  getNights(dayCount: number) {
    if (dayCount < 0) {
      return "";
    } else if (dayCount == 1) {
      return "Night";
    } else {
      return "Nights";
    }
  }

  render() {
    let dayCount = this.getDayCount();
    return (
      <div key={this.props.data} className="w-7/12 flex flex-row my-8">
        <div className="flex flex-col border border-solid rounded-sm py-3 z-30 px-4 w-7/12 shadow-lg ">
          <span
            key={this.props.dayCount}
            className="font-medium text-lg text-gray-700"
          >
            <span className={this.getDayCountStyle(dayCount)}>
              {this.getDayCount()}{" "}
            </span>
            {this.getNights(dayCount)} -
            <span className="text-gray-400 text-base font-normal">
              {" "}
              {this.props.data.booking_unit}{" "}
            </span>
          </span>

          <span className="text-xs text-gray-300 font-thin mb-2">
            Corporate - {" " + this.props.data.corporate_id}
          </span>

          <span className="text-xs text-gray-500 font-thin mb-1">
            {formatFullDateString(this.props.data.check_in, "short")} -{" "}
            {formatFullDateString(this.props.data.check_out, "short")}
          </span>
          <span className=" text-xs text-gray-800 font-regular">
            {formatAmountForDisplay(this.props.data.rate, "can", true) +
              " per " +
              this.getPricingFrequency()}
          </span>
        </div>

        <CompleteLink
          data={this.props.data}
          linkEnd={""}
          NotificationHandler={this.props.ErrorNote}
          corporate_id={this.props.data.corporate_id}
          corporate_deduction={this.props.data.corporate_deduction}
          openHighRisk={this.props.openHighRisk}
        />
      </div>
    );
  }
}

export default withHooksHOC(BookingSummary);

type postBody = {
  first_name: string;
  last_name: string;
  phone_number: string;
  email?: string;
};

export function CompleteLink(this: any, props: any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.

  let { path, url } = useRouteMatch();
  let history = useHistory();
  let linkEnd = props.linkEnd;
  path = path.replace("/new/manual-booking/info", "");
  const [disabled, setDisabled] = useState(false);
  const [isMonthlyRateZero, setIsMonthlyRateZero] = useState(true);
  const [openZeroModal, setOpenZeroModal] = useState(false);
  const [toggleConfirmModal, setToggleConfirmModal] = useState(false);
  const [bookingCreateData, setBookingCreateData] = useState(null);
  const [unitData, setUnitData] = useState(null);
  const [corporateData, setCorporateData] = useState(null);
  const [parkingData, setParkingData] = useState(null);

  useEffect(() => {
    setIsMonthlyRateZero(
      props.data.rate === 0 ||
        props.data.rate === "0" ||
        props.data.rate == null ||
        props.data.rate == undefined ||
        props.data.rate == ""
    );
  }, [props.data.rate]);

  // Only create new tenant if the info filled out is new so
  const retrieveTenantInformation = async () => {
    let tenantBody = store.getState().bookingReducer.bookingTenant;
    if (tenantBody.id !== -1) {
      return tenantBody;
    }

    let newTenantBody: postBody = {
      phone_number: tenantBody.phone_number.trim(),
      first_name: tenantBody.first_name.trim(),
      last_name: tenantBody.last_name.trim(),
    };
    if (tenantBody.email && tenantBody.email.trim() !== "") {
      newTenantBody.email = tenantBody.email.trim();
    }
    console.log(newTenantBody);

    if (
      newTenantBody.first_name.trim() !== "" &&
      newTenantBody.last_name.trim() !== ""
    ) {
      let newTenant = await fetchRequestJSON(
        "POST",
        Constants.API_PATH_TENANT_CREATE,
        newTenantBody
      );
      console.log(newTenant.data);
      if (newTenant.data) {
        return newTenant.data.id;
      } else {
        return {};
      }
    } else {
      props.NotificationHandler(
        "Warning",
        `Error Retrieving Tenant`,
        "No First Name or Last Name for Tenant, please fill in tenant details"
      );
      return null;
    }
  };

  const dayDiff = (date_a: Date, date_b: Date) => {
    return Math.floor(
      (date_b.getTime() - date_a.getTime()) / (1000 * 60 * 60 * 24)
    );
  };

  // These 2 calculate flat deduction functions are not working yet but once done they should be able to allow people to enter
  // custom flat dollar amount deductions when making a booking. As of right now, when you select the corporate booking tick,
  // you can only enter a percentage to be deducted from the total. These functions will hopefully calculate the booking total
  // correctly (copied functionality from invoice calculation as of now) and allow you to deduct your flat amount if its under
  // the total. However, if Matthew is ok with just a percentage corporate deduction then we can remove these.

  const calculateFlatDeductionPercentThirtyDay = () => {
    const flatFee = store.getState().bookingReducer.corporate_deduction;
    const basicBookingInfo = store.getState().bookingReducer.newBooking;
    const totalDays = dayDiff(
      basicBookingInfo.check_in,
      basicBookingInfo.check_out
    );
    let amount = 0;

    // Initial amount set
    if (totalDays >= 30) {
      amount += Number(basicBookingInfo.monthly_rate);
    } else {
      amount += Number(basicBookingInfo.monthly_rate) * (totalDays / 30);
    }

    amount += basicBookingInfo.pet_fee;
    amount += basicBookingInfo.parking_fee;
    amount += basicBookingInfo.cleaning_fee;
    amount += basicBookingInfo.sofa_bed;

    // Add monthly rate per each month
    if (totalDays > 30) {
      for (let i = totalDays - 30; i >= 30; i -= 30) {
        amount += Number(basicBookingInfo.monthly_rate);
      }
    }

    // Add remaining amount for last few days
    if (totalDays % 30 !== 0) {
      let endDate = new Date(basicBookingInfo.check_in);
      endDate.setUTCDate(endDate.getUTCDate() + 29);
      let newBeginDate = new Date(endDate);
      newBeginDate.setUTCDate(newBeginDate.getUTCDate() + 1);
      let lastEndDate = new Date(basicBookingInfo.check_out);
      lastEndDate.setUTCDate(lastEndDate.getUTCDate() - 1);

      let diff = Math.abs(newBeginDate.getTime() - lastEndDate.getTime());
      diff = Math.round(diff / (1000 * 60 * 60 * 24));

      amount += basicBookingInfo.monthly_rate * ((diff + 1) / 30);
    }

    if (flatFee >= amount) {
      return 0;
    } else {
      return flatFee / amount;
    }
  };

  const calculateFlatDeductionPercentMonthly = () => {
    const flatFee = store.getState().bookingReducer.corporate_deduction;
    const basicBookingInfo = store.getState().bookingReducer.newBooking;
    const lastDayOfCheckInMonth = new Date(
      new Date(basicBookingInfo.check_in).getUTCFullYear(),
      new Date(basicBookingInfo.check_in).getUTCMonth() + 1,
      0
    );
    let amount = 0;
    const checkInArr = new Date(basicBookingInfo.check_in)
      .toUTCString()
      .split(" ");
    const checkOutArr = new Date(basicBookingInfo.check_out)
      .toUTCString()
      .split(" ");
    const end =
      checkInArr[2] == checkOutArr[2] && checkInArr[3] == checkOutArr[3]
        ? new Date(basicBookingInfo.check_out).toISOString().substring(0, 10)
        : lastDayOfCheckInMonth.toISOString().substring(0, 10);

    const diff = Math.abs(
      new Date(basicBookingInfo.check_in).getTime() - new Date(end).getTime()
    );
    const diffInDays = Math.round((diff / 1000) * 60 * 60 * 24);

    if (basicBookingInfo.check_out > lastDayOfCheckInMonth) {
      if (new Date(basicBookingInfo.check_in).getUTCDate() === 1) {
        amount += basicBookingInfo.monthly_rate;
      } else {
        amount += basicBookingInfo.monthly_rate * ((diffInDays + 1) / 30);
      }
    } else {
      amount += basicBookingInfo.monthly_rate * ((diffInDays + 1) / 30);
    }

    amount += basicBookingInfo.pet_fee;
    amount += basicBookingInfo.parking_fee;
    amount += basicBookingInfo.cleaning_fee;
    amount += basicBookingInfo.sofa_bed;

    if (moreInvoices(basicBookingInfo.check_in, basicBookingInfo.check_out)) {
      const yearDiff =
        new Date(basicBookingInfo.check_out).getUTCFullYear() -
        new Date(basicBookingInfo.check_in).getUTCFullYear();
      let monthDiff =
        new Date(basicBookingInfo.check_out).getUTCMonth() -
        new Date(basicBookingInfo.check_in).getUTCMonth();
      if (yearDiff > 0) {
        monthDiff += yearDiff * 12;
      }
      // exclude last month
      monthDiff -= 1;
      for (let i = 1; i <= monthDiff; i++) {
        amount += basicBookingInfo.monthly_rate;
      }
    }

    let lastDiff = Math.abs(
      new Date(
        new Date(basicBookingInfo.check_out).getUTCFullYear(),
        new Date(basicBookingInfo.check_out).getUTCMonth(),
        1
      ).getTime() - new Date(basicBookingInfo.check_out).getTime()
    );
    lastDiff = Math.round(lastDiff / (1000 * 60 * 60 * 24));

    // Last month price calc
    if (
      new Date(
        new Date(basicBookingInfo.check_out).getUTCFullYear(),
        new Date(basicBookingInfo.check_out).getUTCMonth() + 1,
        0
      ).getUTCDate() == new Date(basicBookingInfo.check_out).getUTCDate()
    ) {
      amount += basicBookingInfo.monthly_rate;
    } else {
      amount += basicBookingInfo.monthly_rate * ((lastDiff + 1) / 30);
    }

    if (flatFee >= amount) {
      return 0;
    } else {
      return flatFee / amount;
    }
  };

  const isSameYearMonthCheckout = (check_in: Date, check_out: Date) => {
    return (
      check_in.getUTCFullYear() === check_out.getUTCFullYear() &&
      check_in.getUTCMonth() === check_out.getUTCMonth()
    );
  };

  // TODO: Refactor this function by simplifying the logic for determining
  // TODO: whether we require more invoices.
  const moreInvoices = (check_in: Date, check_out: Date) => {
    check_in = new Date(check_in);
    check_out = new Date(check_out);
    if (isSameYearMonthCheckout(check_in, check_out)) {
      return false;
    } else {
      check_out.setUTCDate(check_out.getUTCDate() - 1);
      return !isSameYearMonthCheckout(check_in, check_out);
    }
  };

  const getCorporateDeduction = () => {
    if (!props.corporate_deduction || !props.data.corporate) {
      return 0;
    } else if (props.corporate_deduction < 0) {
      return 0;
    } else if (props.corporate_deduction > 100) {
      return 1;
    } else {
      // 0 <= props.corporate_deduction && props.corporate_deduction <= 1
      return props.corporate_deduction / 100; //want to make it percentage
    }
  };

  const createBookingObject = async () => {
    const tenantInfo = store.getState().bookingReducer.bookingTenant;
    const basicBookingInfo = store.getState().bookingReducer.newBooking;

    const corporateDetails = getCorporateDetails(props.data);
    const corporateDeduction = getCorporateDeduction();

    let bookingCreate = prepareBookingObject(
      props.data,
      tenantInfo,
      basicBookingInfo,
      corporateDetails,
      corporateDeduction
    );

    if (
      props.data.parkingRequired &&
      !(await isParkingAvailable(bookingCreate))
    ) {
      return;
    }

    return bookingCreate;
  };

  const handleSubmit = async () => {
    console.log(props);
    if (openZeroModal) closeModal();

    let bookingCreate = await createBookingObject();

    if (!isValidBooking(bookingCreate)) {
      return props.NotificationHandler(
        "Warning",
        "Error Creating Booking",
        "Please enter all required fields correctly."
      );
    }

    processBookingCreation(bookingCreate);
  };

  const getCorporateDetails = (data: any) => {
    if (!data.corporate)
      return {
        corporateId: null,
        adminId: null,
        cleaningId: null,
        financeId: null,
      };

    return {
      corporateId: data.corporate ? props.corporate_id : null,
      adminId: data.admin_id ?? data.admin_ids?.[0] ?? null,
      cleaningId: data.cleaning_id ?? data.cleaning_ids?.[0] ?? null,
      financeId: data.finance_id ?? data.finance_ids?.[0] ?? null,
    };
  };

  const prepareBookingObject = (
    data: any,
    tenant: any,
    booking: any,
    corporate: any,
    corporateDeduction: number
  ) => {
    const isExistingBooking = !!booking;
    const isNewTenant = !tenant.id;

    const baseBooking = {
      apply_hst: data.auto_hst,
      check_in: isExistingBooking ? booking.check_in : data.check_in,
      check_out: isExistingBooking ? booking.check_out : data.check_out,
      unit_id: isExistingBooking ? booking.unit_id : data.unit_id,
      monthly_rate: Number(
        isExistingBooking ? booking.monthly_rate : data.rate
      ),
      cleaning_frequency: data.cleaning_frequency,
      cleaning_fee: data.cleaning_fee,
      created_by: `${tenant.first_name ?? "N/A"} ${tenant.last_name ?? "N/A"}`,
      pets: data.pet_fee > 0,
      booking_type: "thirty_day",
      corporate_id: corporate.corporateId,
      corporate_admin_id: corporate.adminId,
      corporate_cleaning_id: corporate.cleaningId,
      corporate_finance_id: corporate.financeId,
      booking_notes: data.booking_notes,
      parking_requested: false,
      deposit_expiry: data.deposit_expiry,
      deposit_amount: Number(data.deposit),
      pet_fee: data.pet_fee,
      parking_fee: data.parking_fee,
      corporate_deduction: corporateDeduction,
      hard_checkout: data.hard_checkout,
      fobs: 0,
      keys: 0,
      parking_fobs: 0,
      mail_keys: 0,
      parking: null as any,
      ru_reservation_id: data?.rentalsUnitedReservationID?.length
        ? data.rentalsUnitedReservationID
        : null,
      sofa_bed: data.sofa_bed,
      add_ons: data.add_ons,
    };

    if (isNewTenant) {
      return {
        ...baseBooking,
        first_name: tenant.first_name || "N/A",
        last_name: tenant.last_name || "N/A",
        email: tenant.email || null,
        phone_number: tenant.phone_number || null,
      };
    } else {
      return {
        ...baseBooking,
        tenant_id: tenant.id,
        first_name: tenant.first_name || "N/A",
        last_name: tenant.last_name || "N/A",
        email: tenant.email || null,
        phone_number: tenant.phone_number || null,
      };
    }
  };

  // check api return message for error, then send notification
  const isValidBooking = (booking: any) => {
    if (!booking) return false;

    if (
      typeof booking.check_in === "string" &&
      typeof booking.check_out === "string" &&
      new Date(booking.check_in) > new Date(booking.check_out)
    ) {
      props.NotificationHandler(
        "Warning",
        "Error Creating Booking",
        "Check-out date must be after check-in date."
      );
      return false;
    }

    if (booking.monthly_rate < 0) {
      props.NotificationHandler(
        "Warning",
        "Error Creating Booking",
        "Monthly rate cannot be less than 0."
      );
      return false;
    }

    if (!booking.corporate_id && !booking.email) {
      props.NotificationHandler(
        "Warning",
        "Error Creating Booking",
        "Tenant must have an email or corporate ID."
      );
      return false;
    }

    return true;
  };

  const isParkingAvailable = async (booking: any) => {
    const parkingStatus = await fetchGetJSON(
      `${Constants.API_PATH_PARKING_OVERLAP_CHECK}?start_date=${
        booking.check_in
      }&end_date=${booking.check_out}&parking_spot_id=${+props.data
        .parking_spot_id}`
    );

    if (parkingStatus.status !== "Success") {
      props.NotificationHandler(
        "Warning",
        "Parking Conflict Detected",
        parkingStatus.data
      );
      return false;
    }

    booking.parking = {
      parking_notes: props.data.parking_notes,
      parking_spot_id: +props.data.parking_spot_id,
    };
    booking.parking_requested = true;
    return true;
  };

  const processBookingCreation = async (bookingCreate: any) => {
    props.NotificationHandler(
      "Please wait",
      "Creating Booking",
      "Processing your booking..."
    );
    setDisabled(true);

    const createBookingStatus = await fetchRequestJSON(
      "POST",
      Constants.API_PATH_BOOKING_CREATE,
      bookingCreate
    );

    if (createBookingStatus.status !== "Success") {
      props.NotificationHandler(
        "Error",
        "Error Creating Booking",
        createBookingStatus.status
      );
      return;
    }

    props.openHighRisk();
    props.NotificationHandler(
      "Success",
      "Successfully Created Booking",
      "Find its associated invoice in the invoice tab"
    );
    history.push(`${path}`);
  };

  const openModal = () => {
    setOpenZeroModal(true);
    setDisabled(true);
  };

  const closeModal = () => {
    setOpenZeroModal(false);
    setDisabled(false);
  };

  const handleConfirmModal = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    console.log("Fetching data before opening modal...");
    const dataLoaded = await fetchData();
    if (dataLoaded) {
      console.log("Data loaded successfully, opening modal...");
      toggle();
    } else {
      console.log("Data loading failed, modal will not open.");
    }
  };

  const toggle = () => {
    setToggleConfirmModal((prev) => !prev);
    setDisabled((prev) => !prev);
  };

  const fetchData = async () => {
    try {
      const bookingData = await createBookingObject();
      bookingData.check_in = new Date(bookingData.check_in)
        .toISOString()
        .split("T")[0];
      bookingData.check_out = new Date(bookingData.check_out)
        .toISOString()
        .split("T")[0];
      if (!bookingData.corporate_id) {
        if (bookingData.cleaning_fee < 200) {
          bookingData.cleaning_fee = 200;
        }
      }
      console.log(bookingData);

      if (!bookingData) {
        throw new Error("Booking data is null or undefined");
      }

      const unit = await fetchGetJSON(
        `${Constants.API_PATH_UNIT_GET}${bookingData.unit_id}`
      );

      if (!unit || !unit.data) {
        throw new Error("Unit data is missing");
      }

      const corp = await fetchGetJSON(
        `${Constants.API_PATH_CORP_GET}${bookingData.corporate_id}`
      );

      if (!corp || !corp.data) {
        throw new Error("Corporate data is missing");
      }

      const parking = await fetchGetJSON(
        `${Constants.API_PATH_PARKING_SPOT_GET_ALL_ACTIVE}`
      );

      if (!parking || !parking.data) {
        throw new Error("Parking data is missing");
      }

      const parkingSpot = parking.data.find(
        (spot: any) => spot.id === bookingData.parking?.parking_spot_id
      );

      await new Promise((resolve) => {
        setBookingCreateData(bookingData);
        setUnitData(unit.data);
        setCorporateData(corp.data);
        setParkingData(parkingSpot);
        resolve(undefined);
      });

      return true;
    } catch (error) {
      console.error("Error fetching data:", error);
      return false;
    }
  };

  return (
    <div>
      <ConfirmationModal
        isOpen={toggleConfirmModal}
        onClose={toggle}
        onConfirm={handleSubmit}
        title="Proceed with creating booking?"
        component={
          <ConfirmationModalContent
            data={bookingCreateData}
            unitData={unitData}
            corpData={corporateData}
            parkingData={parkingData}
          />
        }
      />

      {isMonthlyRateZero && (
        <ZeroMonthlyFeeModal
          BookingData={props.data}
          open={openZeroModal}
          closeModal={closeModal}
          submit={handleSubmit}
        />
      )}
      <button
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          if (isMonthlyRateZero) {
            openModal();
          } else {
            handleConfirmModal(event);
          }
        }}
        disabled={disabled}
        className="font-SF-Pro shadow-lg transition text-md duration-100 w-44 h-10 bg-green-500 hover:bg-green-400 text-white font-bold py-2 rounded-sm pr-1 flex flex-row justify-center align-center ml-3"
      >
        {!isMonthlyRateZero && disabled ? "Creating Booking..." : "Complete"}
      </button>

      {/* can be deprecated */}
      {/* <button
        onClick={handleSubmit}
        disabled={disabled}
        className="font-SF-Pro shadow-lg transition text-md duration-100 w-44 h-10 bg-green-500 hover:bg-green-400 text-white font-bold py-2 rounded-sm pr-1 flex flex-row justify-center align-center ml-3"
      >
        {disabled ? 'Creating Booking...' : 'Complete'}
      </button> */}
    </div>
  );
}

interface ConfirmationModalContentProps {
  data: any;
  unitData: any;
  corpData: any;
  parkingData: any;
}

const ConfirmationModalContent = ({
  data,
  unitData,
  corpData,
  parkingData,
}: ConfirmationModalContentProps) => {
  const [nightlyRate, setNightlyRate] = useState(0);
  const [monthlyRate, setMonthlyRate] = useState(0);

  useEffect(() => {
    if (data?.booking_type === "nightly") {
      setNightlyRate(data.monthly_rate);
      setMonthlyRate(data.monthly_rate * 30);
    } else {
      setNightlyRate(data.monthly_rate / 30);
      setMonthlyRate(data.monthly_rate);
    }
  }, [data, unitData, corpData, parkingData]);
  return (
    <div className="p-4 mb-2 border rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-3 text-green-500">
        <u>Booking Summary</u>
      </h2>

      {data && (
        <>
          {data.first_name && data.last_name && (
            <p>
              <b className="text-green-500 font-semibold">Tenant: </b>
              {data.first_name} {data.last_name}
            </p>
          )}
          <p>
            <b className="text-green-500 font-semibold">Tenant Email: </b>
            {data.email ?? "N/A"}
          </p>

          {corpData &&
            corpData !== "Could not retrieve corporate with ID null" && (
              <p>
                <b className="text-green-500 font-semibold">Corporate: </b>
                {corpData?.corporate_name ?? "N/A"}
              </p>
            )}

          <br />

          {unitData && (
            <p>
              <b className="text-green-500 font-semibold">Unit: </b>
              {unitData?.suite_name ?? "N/A"}
            </p>
          )}

          <p>
            <b className="text-green-500 font-semibold">Nightly Rate: </b>$
            {nightlyRate} per Night
          </p>
          <p>
            <b className="text-green-500 font-semibold">Monthly Rate: </b>$
            {monthlyRate} per Month
          </p>

          <br />

          <p>
            <b className="text-green-500 font-semibold">Check-In: </b>
            {data?.check_in ?? "N/A"}
          </p>
          <p>
            <b className="text-green-500 font-semibold">Check-Out: </b>
            {data?.check_out ?? "N/A"}
          </p>

          {data?.cleaning_fee > 0 && (
            <p>
              <b className="text-green-500 font-semibold">
                Turnover Cleaning Fee:{" "}
              </b>
              ${data.cleaning_fee}
            </p>
          )}
          {data?.pet_fee > 0 && (
            <p>
              <b className="text-green-500 font-semibold">Pet Fee: </b>$
              {data.pet_fee}
            </p>
          )}
          {data?.hard_checkout && (
            <p>
              <b className="text-green-500 font-semibold">Hard Checkout: </b>Yes
            </p>
          )}

          {data?.add_ons?.length > 0 && (
            <div>
              <br />
              <p>
                <b className="text-green-500 font-semibold">Add-Ons:</b>
              </p>
              {data?.add_ons?.map(
                (addOn: { name: string; price: number }, index: number) => (
                  <div key={index}>
                    <p>
                      {addOn.name}: ${addOn.price}
                    </p>
                    {addOn.name === "Regular Cleaning Fee" && (
                      <p>Cleaning Frequency: {data.cleaning_frequency}</p>
                    )}
                  </div>
                )
              )}
            </div>
          )}

          {parkingData && (
            <div>
              <br />
              <p>
                <b className="text-green-500 font-semibold">Parking Spot: </b>
                {parkingData?.parking_spot_location ?? "N/A"} ||{" "}
                {parkingData?.parking_spot_level ?? "N/A"}
              </p>
              <p>
                <b className="text-green-500 font-semibold">Parking Fee: </b>$
                {data?.parking_fee ?? 0}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
