import React, { Component, Suspense } from "react";
import ViewSheet from "../Units/InfoSheetComponents/ViewSheet";
import TabList from "../TabList";
import KeysInfo from "./KeysInfo";
import Label from "../LabelWrap";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import { BsFillFlagFill } from "react-icons/bs";
import { BookingFiles } from "./FileUpload";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import Modal from "../Modal";
import NewInvoice from "../Invoices/NewInvoice";
import EditBooking from "../Bookings/EditBooking";
import TransferModal from "../Bookings/TransferModal";
import { FlagModal } from "./FlagModal";
import InvoiceQuickView from "../Dashboards/Finance/InvoiceQuickView";
import { MdDoneAll, MdOpenInNew } from "react-icons/md";
import CheckInProfileCard from "./CheckInProfileCard";
import { withHooksHOC } from "../Notifications/NotificationProvider";
import SwapModal from "./SwapModal";
import { Button } from "@mui/material";
import dayjs from "../../utils/Dayjs";
import ParkingProfileCard from "../Parking/Parking Spots Components/Read/ParkingProfileCard";
import CleaningsProfileCard from "./CleaningsProfileCard";
import { localRoute } from "../../api-requests/api-gateway";
import {
  AddOns,
  Booking,
  Corporate,
  CorporateContact,
  Extension,
  Invoice,
  Parking,
  Tenant,
  Unit,
} from "../../utils/Types";
import BookingHistory from "./BookingHistory";
import EditPortalModal from "./EditPortalModal";
import { ReleaseModal } from "./ExpireModal";
import SalesOrderProfile from "./InvoiceProfile/SalesOrderProfile";
import AddOnsProfileCard from "./AddOnsProfileCard";
import AddOnsModal from "./AddOnsModal";

// const NewInvoice = React.lazy(() => import("../Invoices/NewInvoice"));
const ContactProfileCard = React.lazy(() => import("./ContactProfileCard"));
// const CheckInProfileCard = React.lazy(()=>import("./CheckInProfileCard"))
const CheckOutProfileCard = React.lazy(() => import("./CheckOutProfileCard"));
const InvoiceProfileCard = React.lazy(
  () => import("./InvoiceProfile/InvoiceProfileCard")
);
const RateModal = React.lazy(() => import("./RateModal"));
const CancelModal = React.lazy(() => import("./CancelModal"));
const CheckOutModal = React.lazy(() => import("./CheckOut/CheckOutModal"));
const EditOptionPanel = React.lazy(() => import("../EditOptionPanel"));
const BreadCrumb = React.lazy(() => import("../BreadCrumb"));

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

type Props = {
  history: any;
  ErrorNote: any;
};

type ReturnData = {
  status: string;
  data: {
    booking_data: Booking;
    unit_data: Unit;
    main_tenant_data: Tenant;
    other_tenant_data: Tenant[];
    invoice_data: Invoice[];
    corporate_data: Corporate;
    corporate_contacts_data: CorporateContact[];
    extension_data: Extension;
    parkings_data: Parking[];
    addOns: AddOns[];
  };
};

type SwapData = {
  first_booking_id: number | string;
  first_check_in: Date | string;
  first_check_out: Date | string;
  first_unit_name: string;
  second_booking_id: number;
  second_unit_name: string;
  second_check_in: Date | string;
  second_check_out: Date | string;
  swap_start_date: Date | string;
};

type State = {
  // top left corner (booking information)
  booking_type: string;
  booking_id: string;
  booking_notes: string;
  cancelled: boolean;
  // check_in_time: Date;
  flagged: boolean;
  flag_reason: string;
  rate: number; // TODO: Isn't this the same as monthly_rate?

  // check in tab related state
  check_in: any;
  check_in_notes: string;
  confirmed: boolean;
  parking_required: boolean;
  first_rent_required: boolean;
  suite_number: number;
  new_check_in: any;
  is_early_check_in: boolean;
  is_check_in_changed: boolean;
  // check out tab related state
  check_out: any;
  check_out_notes: string;
  check_out_confirmed: boolean;

  // corporate related state
  corporate: boolean;
  corporate_id: number;
  corporate_name: string;

  admin_contact: string;
  cleaning_contact: string;
  finance_contact: string;

  admin_contact_email: string;
  cleaning_contact_email: string;
  finance_contact_email: string;

  admin_contact_phone: string;
  cleaning_contact_phone: string;
  finance_contact_phone: string;

  admin_policy: string;
  finance_policy: string;
  cleaning_policy: string;

  // deposits and payment related state
  deposit_policy: boolean;
  credit_fee: boolean;
  payment_type: string;
  required_payment_for_access: boolean;
  pets: boolean;
  manual_invoicing: boolean;
  auto_HST: boolean;
  cleaning_fee: boolean;
  hard_check_out: boolean;

  // miscellaneous
  pricing_type: string;
  confirmed_checkin_time: string; // TODO: Isn't this the same as check_in?
  cleaning_frequency: string;
  info: any;
  first_invoice_id: number;
  error: boolean;
  light: string;
  has_add_ons: boolean;

  // tenant related state
  tenant_name: string;
  tenant_email: string;
  tenant_phone: string;
  tenant_id: number;
  tenants: any[];
  other_tenants: any[];

  // toggle state
  toggleAgreement: boolean;
  toggleBlur: boolean;
  toggleCancel: boolean;
  toggleCheckOut: boolean;
  toggleInvoice: boolean;
  toggleNewInvoice: boolean;
  toggleRate: boolean;
  toggleTransfer: boolean;
  toggleSwap: boolean;
  toggleMultipleBookings: boolean;
  editToggle: boolean;
  cancelModalToggle: boolean;
  newCheckInModalToggle: boolean;
  toggleFlag: boolean;
  toggleAddOns: boolean;

  // info keys information
  fob_count: number;
  key_count: number;
  mailkey_count: number;
  park_fob_count: number;
  fob_received: number;
  key_received: number;
  mailkey_received: number;
  park_fob_recieved: number;
  num_outstanding_invoices: number;
  suite: string;
  building: string;
  monthly_rate: number;

  unit_id: number;
  unit_name: string;
  hard_checkout: boolean;

  keys_confirm: boolean;
  cleaning_confirm: boolean;
  payment_confirm: boolean;

  created_at: string;
  updated_at: string;
  NTVDate: string;
  expiry: string;

  transferred: boolean;

  //Swap Booking info:
  swapData: SwapData;
  multipleData: any;
  errorMessage: string;
  keyCode: number;

  extension_date: string;
  eligibleAgreement: boolean;
  netZero: boolean;

  parkingExist: boolean; //Boolean for if the parking exist in our admin system
  parkingInfo: any; //Need to add Parking Model here

  toggleHistory: boolean;
  historyTitle: string;
  historyContent: string;

  editPortalToggle: boolean;
  editExpiryToggle: boolean;

  released: boolean;
  userType: string[];

  ru_reservation_id: number;
  invoice_data: any[];
  reduceTabSize: boolean;

  addOns: any[];

  // toggleNetSuiteQuickView: boolean;
  // netSuiteInvoiceData: any;
};

class BookingProfile extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.goBack = this.goBack.bind(this);

    this.state = {
      // top left corner (booking information)
      booking_type: "monthly",
      booking_id: "",
      booking_notes: "",
      flagged: false,
      flag_reason: null,
      rate: -1, // TODO: Isn't this the same as monthly_rate?
      cancelled: false,

      // check in tab related state
      check_in: new Date(),
      new_check_in: new Date(),
      is_early_check_in: true,
      is_check_in_changed: false,
      check_in_notes: "",
      confirmed: true,
      parking_required: true,
      first_rent_required: true,
      suite_number: -1,

      // check out tab related state
      check_out: new Date(),
      check_out_notes: "",
      check_out_confirmed: false,

      // corporate related state
      corporate: true,
      corporate_id: -1,
      corporate_name: "N/A",

      admin_contact: "",
      cleaning_contact: "",
      finance_contact: "",

      admin_contact_email: "",
      cleaning_contact_email: "",
      finance_contact_email: "",

      admin_contact_phone: "",
      cleaning_contact_phone: "",
      finance_contact_phone: "",

      admin_policy: "",
      finance_policy: "",
      cleaning_policy: "",

      // deposits and payment related state
      deposit_policy: false,
      credit_fee: false,
      payment_type: "",
      required_payment_for_access: false,
      pets: false,
      manual_invoicing: false,
      auto_HST: false,
      cleaning_fee: false,
      hard_check_out: false,

      // miscellaneous
      pricing_type: "",
      confirmed_checkin_time: "", // TODO: Isn't this the same as check_in?
      cleaning_frequency: "",
      info: undefined,
      first_invoice_id: -1,
      error: false,
      light: "",
      has_add_ons: false,

      // tenant related state
      tenant_name: "",
      tenant_email: "",
      tenant_phone: "",
      tenant_id: -1,
      tenants: [],
      other_tenants: [],

      // toggle state
      toggleAgreement: false,
      toggleBlur: false,
      toggleCancel: false,
      toggleCheckOut: false,
      toggleInvoice: false,
      toggleNewInvoice: false,
      toggleRate: false,
      toggleTransfer: false,
      editToggle: false,
      cancelModalToggle: false,
      newCheckInModalToggle: false,
      toggleSwap: false,
      toggleMultipleBookings: false,
      toggleFlag: false,
      toggleAddOns: false,

      // info keys information
      fob_count: 0,
      key_count: 0,
      mailkey_count: 0,
      park_fob_count: 0,
      fob_received: 0,
      key_received: 0,
      mailkey_received: 0,
      park_fob_recieved: 0,
      num_outstanding_invoices: 0,
      suite: "",
      building: "",
      monthly_rate: -1,

      unit_id: null,
      unit_name: "",
      hard_checkout: false,

      keys_confirm: false,
      cleaning_confirm: false,
      payment_confirm: false,

      created_at: "",
      updated_at: "",
      NTVDate: "",
      expiry: "",

      transferred: false,

      //Swap Booking info:
      swapData: {} as SwapData,
      multipleData: [],
      errorMessage: "",

      keyCode: 0,
      extension_date: "",
      eligibleAgreement: false,
      netZero: true, //default set this to true since default is Retail,

      parkingExist: false,
      parkingInfo: null,

      toggleHistory: false,
      historyTitle: "",
      historyContent: "",

      editPortalToggle: false,
      editExpiryToggle: false,

      released: false,

      userType: [],
      ru_reservation_id: null,

      invoice_data: [],
      reduceTabSize: false,
      // toggleNetSuiteQuickView: false,

      // netSuiteInvoiceData: [],
      addOns: [],
    };

    // this.populateState()
  }

  loadUserType = async () => {
    let type = await store.getState().userReducer.userType;
    console.log(type);
    this.setState({
      userType: type,
    });
  };

  goBack() {
    this.props.history.push("/user/dashboard");
  }

  setCorporate = (
    tenant: Tenant,
    corporate: Corporate,
    corporateContacts: CorporateContact[]
  ) => {
    let corpData = corporate;
    this.setState({
      required_payment_for_access: corpData.requires_initial_payment,
    });

    const allContacts: CorporateContact[] = corporateContacts;

    let admin: CorporateContact = {
      id: -1,
      first_name: "",
      last_name: "",
      email: "",
    };

    let finance: CorporateContact = {
      id: -1,
      first_name: "",
      last_name: "",
      email: "",
    };

    let cleaning: CorporateContact = {
      id: -1,
      first_name: "",
      last_name: "",
      email: "",
    };

    let corporateAdminContact: CorporateContact = allContacts[0]; //Admin
    let corporateFinanceContact: CorporateContact = allContacts[1]; //Finance
    let corporateCleaningContact: CorporateContact = allContacts[2]; //Cleaning

    if (corpData) {
      // set admin contact
      admin.email = corporateAdminContact?.email ?? tenant.email;
      // set finance contact
      finance.email = corporateFinanceContact?.email ?? tenant.email;
      // set cleaning contact
      cleaning.email = corporateCleaningContact?.email ?? tenant.email;

      // If needed in the future, just add back the logic for contact names and set it here
      this.setState({
        admin_contact_email: admin.email,
        cleaning_contact_email: cleaning.email,
        finance_contact_email: finance.email,
        admin_policy: corpData.admin_communication_policy,
        finance_policy: corpData.finance_communication_policy,
        cleaning_policy: corpData.cleaning_communication_policy,
        corporate_name: corpData.corporate_name,
        deposit_policy: corpData.deposit_policy,
        credit_fee: corpData.credit_fee,
        required_payment_for_access: corpData.requires_initial_payment,
        payment_type: corpData.method_of_payment,
      });
    }
  };

  getFobCount(reduxData: State, bookingData: Booking) {
    if (reduxData.fob_count) {
      return reduxData.fob_count;
    } else if (bookingData.fobs) {
      return bookingData.fobs;
    } else {
      return 0;
    }
  }

  getKeyCount(reduxData: State, bookingData: Booking) {
    if (reduxData.key_count) {
      return reduxData.key_count;
    } else if (bookingData.keys) {
      return bookingData.keys;
    } else {
      return 0;
    }
  }

  getMailKeyCount(reduxData: State, bookingData: Booking) {
    if (reduxData.mailkey_count) {
      return reduxData.mailkey_count;
    } else if (bookingData.mail_keys) {
      return bookingData.mail_keys;
    } else {
      return 0;
    }
  }

  getParkFobCount(reduxData: State, bookingData: Booking) {
    if (reduxData.park_fob_count) {
      return reduxData.park_fob_count;
    } else if (bookingData.parking_fobs) {
      return bookingData.parking_fobs;
    } else {
      return 0;
    }
  }

  componentDidMount = async () => {
    this.loadUserType();
    await this.loadData();

    store.dispatch({
      type: "bookingProfileUpdate",
      profileUpdate: this.loadData,
    });
  };

  loadData = async () => {
    let bookingId: number = Number(
      new URLSearchParams(window.location.search).get("id")
    );

    let bookingProfileData: ReturnData = await fetchGetJSON(
      `${Constants.API_PATH_BOOKING_PROFILE}${bookingId}`
    );

    console.log(bookingProfileData);

    let allInvoices: Invoice[] = bookingProfileData?.data?.invoice_data;

    this.setState({
      invoice_data: allInvoices,
    });

    let invoiceData: Invoice = { id: -1 };
    if (allInvoices) {
      allInvoices.forEach((invoice) => {
        if (invoice.booking_id === bookingId) {
          invoiceData = invoice;
          return;
        }
      });

      let outstandingInvoicesCount: number =
        this.state.num_outstanding_invoices;
      outstandingInvoicesCount += allInvoices.filter((invoice: Invoice) => {
        return invoice.booking_id === bookingId && !invoice.paid;
      }).length;
      this.setState({ num_outstanding_invoices: outstandingInvoicesCount });
    }

    const parkings: Parking[] = bookingProfileData?.data?.parkings_data;

    if (parkings?.length > 0) {
      console.log(parkings);

      this.setState({
        parkingExist: true,
        parkingInfo: parkings[0],
      });
    }

    const addOns: any[] = bookingProfileData?.data?.addOns;

    if (addOns?.length > 0) {
      console.log(addOns);

      this.setState({
        addOns: addOns,
        has_add_ons: true,
      });
    }

    let bookingData: Booking = {
      check_in: new Date(),
      check_in_notes: "",
      check_out: new Date(),
      check_out_notes: "",
      confirmed: true,
      corporate_id: -1,
      flagged: false,
      id: -1,
      monthly_rate: 0,
      other_tenants: [],
      pets: false,
      parking_requested: true,
      tenant_id: -1,
      unit_id: 0,
      light: "",
      hard_checkout: false,
      keys_confirm: false,
      cleaning_confirm: false,
      payment_confirm: false,
      keyCode: 0,
      release: false,
      ru_reservation_id: 0,
    };
    let tenantData: Tenant = {
      id: -1,
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
    };

    if (
      bookingProfileData.status === "Success" &&
      bookingProfileData &&
      bookingProfileData.data.booking_data
    ) {
      bookingData = bookingProfileData.data.booking_data;
      let allTenants: Tenant[] = [];
      tenantData = bookingProfileData.data.main_tenant_data;

      allTenants.push(tenantData);
      const associatedTenants: Tenant[] =
        bookingProfileData.data.other_tenant_data;

      allTenants.push(...associatedTenants);

      this.setState({
        tenant_email: bookingProfileData.data.main_tenant_data.email,
        tenants: allTenants,
        tenant_id: tenantData.id,
      });

      // if (allTenants.status != "Success") {
      //   alert("This tenant does not exist.");
      //   this.goBack();
      // } else if (allTenants.data === {} || allTenants.data.length === 0) {
      //   alert("This tenant does not exist.");
      //   this.goBack();
      // } else {
      //   this.setState({ tenants: allTenants.data });
      //   for (let i = 0; i < allTenants.data.length; i++) {
      //     if (allTenants.data[i].id == bookingData.tenant_id) {
      //       tenantData = allTenants.data[i];
      //       break;
      //     }
      //   }
      // }
    } else {
      // alert("This booking does not exist");
      // this.goBack();
    }

    //Set NTV Date for booking:
    let NTV: number = 30;

    // Set expiry and NTV for booking, as well as set netZero state
    let expiryStatus: string = "";
    if (bookingProfileData?.data?.corporate_data) {
      this.setCorporate(
        tenantData,
        bookingProfileData.data.corporate_data,
        bookingProfileData.data.corporate_contacts_data
      );
      const corporate: Corporate = bookingProfileData?.data.corporate_data;
      NTV = corporate?.notice_to_vacate;

      if (!corporate?.requires_initial_payment) {
        this.setState({ netZero: false });
      }

      if (corporate?.expiry && corporate?.expiry !== -1) {
        expiryStatus = corporate?.expiry + " hours";
      } else {
        expiryStatus = "Never";
      }
    } else {
      expiryStatus = "120 hours";
    }

    // let reduxData = store.getState().checkInReducer;
    if (!bookingData.other_tenants) {
      bookingData.other_tenants = [];
    }

    const NTVDateString: string = dayjs(bookingData?.check_out)
      .subtract(NTV, "day")
      .format("YYYY-MM-DD");

    if (bookingData) {
      this.setState({
        booking_id: bookingData.id.toString(),
        booking_notes: bookingData.booking_notes,
        booking_type: bookingData.booking_type,
        cancelled: bookingData.cancelled,
        check_in: dayjs(bookingData?.check_in).format("YYYY-MM-DD"),
        check_in_notes: bookingData.check_in_notes,
        check_out: bookingData?.check_out,
        check_out_notes: bookingData.check_out_notes,
        check_out_confirmed: bookingData.confirmed_check_out,
        cleaning_frequency: bookingData.cleaning_frequency,
        confirmed: bookingData.confirmed,
        corporate_id: bookingData.corporate_id,
        first_invoice_id: invoiceData.id,
        // fob_count: this.getFobCount(reduxData, bookingData),
        fob_count: bookingData.fobs ? bookingData.fobs : 0,
        flagged: bookingData.flagged,
        flag_reason: bookingData.flag_reason,
        hard_checkout: bookingData.hard_checkout,
        // key_count: this.getKeyCount(reduxData, bookingData),
        key_count: bookingData.keys ? bookingData.keys : 0,
        light: bookingData.light,
        // mailkey_count: this.getMailKeyCount(reduxData, bookingData),
        mailkey_count: bookingData.mail_keys ? bookingData.mail_keys : 0,
        monthly_rate: bookingData.monthly_rate,
        parking_required: bookingData.parking_requested,
        // park_fob_count: this.getParkFobCount(reduxData, bookingData),
        park_fob_count: bookingData.parking_fobs ? bookingData.parking_fobs : 0,
        pets: bookingData.pets,
        rate: bookingData.monthly_rate, // TODO: Isn't rate the same as monthly_rate?
        suite_number: bookingData.unit_id,
        tenant_name: `${tenantData.first_name} ${tenantData.last_name}`,
        tenant_email: tenantData.email,
        tenant_phone: tenantData.phone_number,
        // info: {
        //   booking: bookingData,
        //   tenant:
        // }
        other_tenants: bookingData.other_tenants,
        unit_id: bookingData.unit_id,

        keys_confirm: bookingData.keys_confirm,
        cleaning_confirm: bookingData.cleaning_confirm,
        payment_confirm: bookingData.payment_confirm,

        created_at: new Date(bookingData.created_at).toLocaleString("en-US", {
          timeZone: "America/New_York",
        }),
        updated_at: new Date(bookingData.updated_at).toLocaleString("en-US", {
          timeZone: "America/New_York",
        }),
        NTVDate: NTVDateString,
        expiry: expiryStatus,

        keyCode: bookingData.keyCode,
        released: bookingData.release,

        ru_reservation_id: bookingData.ru_reservation_id,
      });

      //Check day difference:
      const checkIn = dayjs.tz(bookingData.check_in, "America/Toronto");
      const checkOut = dayjs.tz(bookingData.check_out, "America/Toronto");

      if (checkOut.diff(checkIn, "day") >= 30) {
        console.log("Changing eligibleAgreement");
        this.setState({
          eligibleAgreement: true,
        });
      }
    }

    const getUnitData: Unit = bookingProfileData?.data.unit_data;
    if (getUnitData) {
      this.setState({
        unit_name: getUnitData.suite_name,
      });
    }

    //Check if extension exist:
    const extension: Extension = bookingProfileData.data.extension_data;
    if (extension) {
      this.setState({ extension_date: extension.extend_until });
    }

    const reduceTabSize =
      this.state.rate !== 0 && (this.state.invoice_data?.length > 0 || false);

    this.setState({ reduceTabSize });
  };

  toggleInvoices = () => {
    this.setState({
      toggleNewInvoice: !this.state.toggleNewInvoice,
      toggleBlur: !this.state.toggleBlur,
    });
  };

  handleEditBarToggle = () => {
    if (
      !(
        dayjs().format("YYYY-MM-DD") >
        dayjs(this.state.check_out).format("YYYY-MM-DD")
      )
    ) {
      this.setState({ editToggle: !this.state.editToggle });
    } else {
      const NotificationHandler = this.props.ErrorNote;
      NotificationHandler(
        "Error",
        "Error",
        "Cannot edit a booking past it's checkout date"
      );
    }
  };

  handleBookingAgreement = () => {
    window.location.href = `${window.location.origin}/bookings/agreement/${this.state.booking_id}`;
  };

  // handleNetSuiteInvoiceChange = (event: any) => {
  //   // console.log("Clicked on NetSuite Handle Invoice Change");
  //   // console.log(event);
  //   // this.setState({
  //   //   toggleNetSuiteQuickView: !this.state.toggleNetSuiteQuickView,
  //   //   netSuiteInvoiceData: event,
  //   // });
  // };
  // TODO: Refactor this function.
  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  // TODO: Refactor this function.
  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  // TODO: Refactor this function.
  handleToggleChange = (id: any, state: boolean) => {
    const isPastCheckOutDate =
      dayjs().format("YYYY-MM-DD") >
      dayjs(this.state.check_out).format("YYYY-MM-DD");
    const isNotReleasedOrCancelled =
      !this.state.released && !this.state.cancelled;
    const allowedRoles = [
      "Admin",
      "Analytics",
      "Finance_Controller",
      "Payments_Offshore",
      "Sales_Manager",
    ];
    const hasAllowedRole = this.state.userType.some((role) =>
      allowedRoles.includes(role)
    );
    const NotificationHandler = this.props.ErrorNote;

    if (hasAllowedRole || (!isPastCheckOutDate && isNotReleasedOrCancelled)) {
      let stateObject = (function (this: typeof id) {
        let returnObj: any = {};
        returnObj[id] = !state;
        return returnObj;
      })();
      this.setState(stateObject);
    } else {
      NotificationHandler(
        "Error",
        "Error",
        "Cannot edit a booking past it's checkout date"
      );
    }
  };

  // TODO: Refactor this function.
  handleAgreement = (state?: any) => {
    if (state && this.state.toggleAgreement === false) {
      this.setState({ toggleAgreement: true });
    } else {
      this.setState({ toggleAgreement: false });
    }
  };

  // TODO: Find a simpler way to send a POST request with the correct body.
  toggleFlag = async () => {
    const NotificationHandler = this.props.ErrorNote;
    if (
      !(
        dayjs().format("YYYY-MM-DD") >
        dayjs(this.state.check_out).format("YYYY-MM-DD")
      )
    ) {
      if (this.state.flagged) {
        const bookingStatus = await fetchGetJSON(
          `${Constants.API_PATH_BOOKING_GET}${this.state.booking_id}`
        );

        let bookingWithID;
        if (bookingStatus.status !== "Success") {
          return;
        } else {
          bookingWithID = bookingStatus.data[0];
        }

        const editBooking = await fetchRequestJSON(
          "PUT",
          Constants.API_PATH_BOOKING_EDIT,
          {
            id: this.state.booking_id,
            fields: {
              flagged: !bookingWithID.flagged,
              flag_reason: null,
            },
          }
        );
        if (editBooking.status !== "Success") {
          NotificationHandler(
            "Error",
            "Flagging booking failed",
            `${editBooking.status}`
          );
        } else {
          this.setState({ flagged: !bookingWithID.flagged });
          this.setState({ flag_reason: null });
          NotificationHandler(
            "Success",
            "Success",
            "Booking is successfully unflagged"
          );
        }
      } else {
        this.handleFlagModal();
      }
    } else {
      NotificationHandler(
        "Error",
        "Error",
        "Cannot edit a booking past it's checkout date"
      );
    }
  };

  setFlagReason = (newFlagReason: any) => {
    this.setState({ flag_reason: newFlagReason });
  };

  handleFlagModal = () => {
    if (
      !(
        dayjs().format("YYYY-MM-DD") >
        dayjs(this.state.check_out).format("YYYY-MM-DD")
      )
    ) {
      this.setState({ toggleFlag: !this.state.toggleFlag });
    } else {
      const NotificationHandler = this.props.ErrorNote;
      NotificationHandler(
        "Error",
        "Error",
        "Cannot edit a booking past it's checkout date"
      );
    }
  };

  handleFlagged = () => {
    this.setState({ flagged: !this.state.flagged });
  };

  handleAddOns = () => {
    this.setState({ toggleAddOns: !this.state.toggleAddOns });
  };

  handleAddOnsData = (data: any) => {
    this.setState({ addOns: data });
  };

  handleInvoiceView = () => {
    this.setState({ toggleInvoice: !this.state.toggleInvoice });
  };

  handleCancelModal = () => {
    console.log("Opening Cancel Modal: ");

    const NotificationHandler = this.props.ErrorNote;

    if (
      !(
        dayjs().format("YYYY-MM-DD") >
        dayjs(this.state.check_out).format("YYYY-MM-DD")
      ) &&
      !this.state.cancelled &&
      !this.state.released
    ) {
      const allowedRoles = ["Admin"];
      const hasAllowedRole = this.state.userType.some((role) =>
        allowedRoles.includes(role)
      );
      if (this.state.ru_reservation_id && !hasAllowedRole) {
        NotificationHandler(
          "Error",
          "Error",
          "No Permissions to Cancel or Release a Rentals United Reservation Booking. Only Tracy, Arlene, and Admin can allow this."
        );
        return;
      }
      this.setState({ cancelModalToggle: !this.state.cancelModalToggle });
    } else {
      if (!this.state.released && !this.state.cancelled) {
        NotificationHandler(
          "Error",
          "Error",
          "Cannot edit a booking past it's checkout date"
        );
      } else {
        NotificationHandler(
          "Error",
          "Error",
          `Booking #ID ${this.state.booking_id} Already ${
            this.state.cancelled ? "Cancelled" : "Released"
          }`
        );
      }
    }
  };

  handleHistoryModal = (historyLog?: any, historyTitle?: string) => {
    this.setState({
      toggleHistory: !this.state.toggleHistory,
      historyTitle: historyTitle,
      historyContent: historyLog,
    });
  };

  handleEditPortalToggle = () => {
    const isPastCheckOutDate =
      dayjs().format("YYYY-MM-DD") >
      dayjs(this.state.check_out).format("YYYY-MM-DD");
    const isNotReleasedOrCancelled =
      !this.state.released && !this.state.cancelled;
    const allowedRoles = [
      "Admin",
      "Analytics",
      "Finance_Controller",
      "Payments_Offshore",
      "Sales_Manager",
    ];
    const hasAllowedRole = this.state.userType.some((role) =>
      allowedRoles.includes(role)
    );

    // Calculate if within 30 seconds of creation
    const isWithinCreationWindow =
      dayjs().diff(dayjs(this.state.created_at), "seconds") <= 30;

    if (isWithinCreationWindow) {
      const NotificationHandler = this.props.ErrorNote;
      NotificationHandler(
        "Error",
        "Error",
        "You cannot edit the booking within the first 30 seconds of its creation."
      );
      return;
    }

    if (hasAllowedRole || (!isPastCheckOutDate && isNotReleasedOrCancelled)) {
      this.setState({
        editPortalToggle: !this.state.editPortalToggle,
      });
    } else {
      const NotificationHandler = this.props.ErrorNote;
      if (!this.state.released && !this.state.cancelled) {
        NotificationHandler(
          "Error",
          "Error",
          "Cannot edit a booking past its checkout date"
        );
      } else {
        NotificationHandler(
          "Error",
          "Error",
          `Booking #ID ${this.state.booking_id} Already ${
            this.state.released ? "Released" : "Cancelled"
          }. No further edits can be made towards this booking.`
        );
      }
    }
  };

  handleExpiryToggle = () => {
    const NotificationHandler = this.props.ErrorNote;
    if (
      !(
        dayjs().format("YYYY-MM-DD") >
        dayjs(this.state.check_out).format("YYYY-MM-DD")
      ) &&
      !this.state.released
    ) {
      const allowedRoles = ["Admin"];
      const hasAllowedRole = this.state.userType.some((role) =>
        allowedRoles.includes(role)
      );

      if (this.state.ru_reservation_id && !hasAllowedRole) {
        NotificationHandler(
          "Error",
          "Error",
          "No Permissions to Cancel or Release a Rentals United Reservation Booking. Only Tracy, Arlene, and Admin can allow this."
        );
        return;
      }

      this.setState({
        editExpiryToggle: !this.state.editExpiryToggle,
      });
    } else {
      if (!this.state.expiry) {
        NotificationHandler(
          "Error",
          "Error",
          "Cannot edit a booking past it's checkout date"
        );
      } else if (this.state.ru_reservation_id) {
        NotificationHandler(
          "Error",
          "Error",
          "Cannot edit check-in or check-out for a Rentals United Reservation from the Admin System. Only allowed from Sales Channel and Rentals United."
        );
      } else {
        NotificationHandler(
          "Error",
          "Error",
          `Booking #ID ${this.state.booking_id} Already Expired`
        );
      }
    }
  };

  handleNewCheckInModal = (newCheckInDate: any, isEarlyCheckin: boolean) => {
    if (
      !(
        dayjs().format("YYYY-MM-DD") >
        dayjs(this.state.check_out).format("YYYY-MM-DD")
      ) &&
      !this.state.ru_reservation_id
    ) {
      this.setState({ new_check_in: newCheckInDate });
      this.setState({ is_early_check_in: isEarlyCheckin });
      this.setState({
        newCheckInModalToggle: !this.state.newCheckInModalToggle,
      });
    } else {
      const NotificationHandler = this.props.ErrorNote;
      let response = "Cannot edit a booking past it's checkout date";
      if (this.state.ru_reservation_id) {
        response =
          "Cannot edit check-in or check-out for a Rentals United Reservation from the Admin System. Only allowed from Sales Channel and Rentals United.";
      }
      NotificationHandler("Error", "Error", response);
    }
  };

  handleConfirmEarly = async () => {
    const NotificationHandler = this.props.ErrorNote;
    if (
      !(
        dayjs().format("YYYY-MM-DD") >
        dayjs(this.state.check_out).format("YYYY-MM-DD")
      ) &&
      !this.state.ru_reservation_id
    ) {
      const confirmDateBody = {
        new_date: this.state.new_check_in,
        booking_id: this.state.booking_id,
      };
      const isEarlyCheckin = this.state.is_early_check_in;

      let res = await fetchRequestJSON(
        "PUT",
        isEarlyCheckin
          ? Constants.API_PATH_EARLY_CHECKIN
          : Constants.API_PATH_LATE_CHECKIN,
        confirmDateBody
      );

      console.log(res);

      if (
        res.status != "Success" &&
        res.status == "Error, another booking is happening during that time."
      ) {
        NotificationHandler(
          "Error",
          "Error",
          `${res.status} Booking #ID: ${res.other_booking_id}`
        );
        return;
      }

      if (res.status == "Success") {
        NotificationHandler(
          "Success",
          `Success, ${
            isEarlyCheckin ? "early" : "late"
          } check in date submitted!`,
          "Please refresh your screen to see the changes."
        );
        this.setState({
          newCheckInModalToggle: !this.state.newCheckInModalToggle,
        });
        this.setState({ is_check_in_changed: true });
      } else if (
        res.status == "Error, new check in date is later than the old one."
      ) {
        NotificationHandler(
          "Warning",
          "New check in date is later than the old check in date."
        );
      } else if (
        res.status == "Error, another booking is happening during that time."
      ) {
        NotificationHandler(
          `Warning", "Another booking is happening during the new check in time: Booking ${res?.other_booking_id}`
        );
      } else if (
        res.status ==
        "Error, new check in date is earlier than the check out date."
      ) {
        NotificationHandler(
          "Warning",
          "Error, new check in date is earlier than the check out date."
        );
      } else if (
        res.status == "Error, new check in date is earlier than the old one."
      ) {
        NotificationHandler(
          "Warning",
          "Error, new check in date is earlier than the old one."
        );
      } else if (res.status == "Error, could not create invoice") {
        NotificationHandler(
          "Error",
          "Check in date was changed, but there was an error in creating the invoice."
        );
      } else {
        NotificationHandler(
          "Error",
          "Error",
          res?.data ? res.data : "Contact Dev Team"
        );
      }
    } else {
      const NotificationHandler = this.props.ErrorNote;
      let response = "Cannot edit a booking past it's checkout date";
      if (this.state.ru_reservation_id) {
        response =
          "Cannot edit check-in or check-out for a Rentals United Reservation from the Admin System. Only allowed from Sales Channel and Rentals United.";
      }
      NotificationHandler("Error", "Error", response);
    }
  };

  cancelBookingModel = async (link: string) => {
    if (
      !(
        dayjs().format("YYYY-MM-DD") >
        dayjs(this.state.check_out).format("YYYY-MM-DD")
      )
    ) {
      let bookingId = new URLSearchParams(window.location.search).get("id");
      bookingId = bookingId ? bookingId : store.getState().bookingReducer.curId;
      const res = await fetchRequestJSON("PUT", link, { id: bookingId });
      return res;
    } else {
      const NotificationHandler = this.props.ErrorNote;
      NotificationHandler(
        "Error",
        "Error",
        "Cannot edit a booking past it's checkout date"
      );
    }
  };

  handleError = (type: string, title: string, message: string) => {
    store.dispatch({
      type: type.toUpperCase(),
      title: title,
      message: message,
    });
  };

  handleSwapToggle = (
    bookingId: number,
    unitName: string,
    checkIn: Date,
    checkOut: Date,
    startDate: any
  ) => {
    console.log("Swap toggle initiated");
    this.setState({
      swapData: {
        first_booking_id: this.state.booking_id,
        first_check_in: dayjs
          .tz(this.state.check_in, "America/Toronto")
          .format("ll"),
        first_check_out: dayjs
          .tz(this.state.check_out, "America/Toronto")
          .format("ll"),
        first_unit_name: this.state.unit_name,
        second_booking_id: bookingId,
        second_unit_name: unitName,
        second_check_in: dayjs.tz(checkIn, "America/Toronto").format("ll"),
        second_check_out: dayjs.tz(checkOut, "America/Toronto").format("ll"),
        swap_start_date: startDate,
      },
    });
    this.handleToggleChange("toggleSwap", this.state.toggleSwap);
  };

  handleMultipleToggle = (
    bookings: any,
    unitName: string,
    message: string,
    secondUnitName?: string
  ) => {
    const allBookings = bookings.map((booking: any, index: number) => {
      //if it is a swap error, the current booking is already returned.
      // Skip the first index (the current booking)
      return {
        id: booking.id,
        check_in: dayjs.tz(booking.check_in, "America/Toronto").format("ll"),
        check_out: dayjs.tz(booking.check_out, "America/Toronto").format("ll"),
        unit_name: secondUnitName && index == 0 ? secondUnitName : unitName,
      };
    });

    //if no second unit name (not a swap error) add current booking to array
    if (!secondUnitName) {
      allBookings.unshift({
        id: Number(this.state.booking_id),
        check_in: dayjs
          .tz(this.state.check_in.toISOString(), "America/Toronto")
          .format("ll"),
        check_out: dayjs
          .tz(this.state.check_out.toISOString(), "America/Toronto")
          .format("ll"),
        unit_name: this.state.unit_name,
      });
    }

    this.setState({
      multipleData: allBookings,
      errorMessage: message,
    });

    this.handleToggleChange("toggleSwap", this.state.toggleSwap);
  };
  confirmCancelModal = async () => {
    let NotificationHandler = this.props.ErrorNote;
    const { history } = this.props;
    const location = window.location;
    let path = location.pathname.split("/");
    const cancelBookingStatus = await this.cancelBookingModel(
      Constants.API_PATH_BOOKING_CANCEL
    );
    if (cancelBookingStatus.status === "Success") {
      NotificationHandler("Success", `Successfully Deleted Booking`, "");
      let bookingId = new URLSearchParams(window.location.search).get("id");
      if (bookingId) {
        history.push(`/${path[1]}/bookings`);
      } else {
        let update = store.getState().bookingReducer.listUpdate;
        update();
        //this.props.handlePopup()
      }
    } else {
      NotificationHandler(
        "Warning",
        `Booking Deletion Unsuccessful`,
        "Already deleted booking"
      );
      let bookingId = new URLSearchParams(window.location.search).get("id");
      if (bookingId) {
        history.push(`/${path[1]}/bookings`);
      } else {
        let update = store.getState().bookingReducer.listUpdate;
        update();
        //this.props.handlePopup()
      }
    }
  };

  navigateParking = () => {
    const url = `${window.location.origin}/user/parkings/parkingprofile/?id=${this.state.parkingInfo.id}`;
    window.open(url, "_blank");
  };

  /* TODO: Extract the booking profile components into their own component files. */
  render() {
    const generateTabs = () => {
      const tabs = [];

      tabs.push(
        <Label label="Check In" key="Check In">
          <Suspense fallback={<div>Loading...</div>}>
            <CheckInProfileCard
              checkinData={this.state}
              checkIn={dayjs(this.state.check_in).format("YYYY-MM-DD")}
              confirmCheckInModal={this.handleNewCheckInModal}
            />
          </Suspense>
        </Label>
      );

      tabs.push(
        <Label label="Add Ons" key="AddOns">
          <AddOnsProfileCard
            bookingId={+this.state.booking_id}
            addOnsData={this.state.addOns}
          />
        </Label>
      );

      if (this.state.parkingExist) {
        tabs.push(
          <Label label="Parking" key="Parking">
            <ParkingProfileCard bookingView={true} />
          </Label>
        );
      }

      tabs.push(
        <Label label="Contact" key="Contact">
          <Suspense fallback={<div>Loading...</div>}>
            <ContactProfileCard contactData={this.state} />
          </Suspense>
        </Label>
      );

      // Conditionally add the "Invoices (Legacy)" tab
      if (this.state.rate !== 0 && this.state.invoice_data?.length > 0) {
        tabs.push(
          <Label label="Invoices (Legacy)" key="Invoices (Legacy)">
            <Suspense fallback={<div>Loading...</div>}>
              <InvoiceProfileCard
                invoiceQuickView={this.handleInvoiceView}
                toggleNewInvoice={this.toggleInvoices}
                handleEditBarToggle={this.handleToggleChange}
                bookingId={this.state.booking_id}
              />
            </Suspense>
          </Label>
        );
      }

      if (this.state.rate !== 0) {
        tabs.push(
          <Label label="Sales Order" key="Sales Order">
            <Suspense fallback={<div>Loading...</div>}>
              <SalesOrderProfile
                bookingId={this.state.booking_id}
                userType={this.state.userType}
                // invoiceQuickView={this.handleInvoiceView}
                // toggleNewInvoice={this.toggleInvoices}
                // handleEditBarToggle={this.handleToggleChange}
              />
            </Suspense>
          </Label>
        );
      }

      // Add other static tabs
      tabs.push(
        <Label label="Files" key="Files">
          <BookingFiles />
        </Label>
      );

      tabs.push(
        <Label label="Check Out" key="Check Out">
          <Suspense fallback={<div>Loading...</div>}>
            <CheckOutProfileCard
              BookingData={this.state}
              checkOut={dayjs(this.state.check_out).format("YYYY-MM-DD")}
              handleCheckOutModal={this.handleToggleChange}
              openCheckOutModal={() => {
                this.handleToggleChange(
                  "toggleCheckOut",
                  this.state.toggleCheckOut
                );
              }}
              monthlyRate={this.state.rate}
            />
          </Suspense>
        </Label>
      );

      tabs.push(
        <Label label="Cleanings" key="Cleanings">
          <CleaningsProfileCard bookingId={+this.state.booking_id} />
        </Label>
      );

      tabs.push(
        <Label label="History" key="History">
          <BookingHistory
            bookingId={+this.state.booking_id}
            historyModalToggle={this.handleHistoryModal}
          />
        </Label>
      );

      return tabs;
    };

    const checkInDate = dayjs.tz(this.state.check_in, "America/Toronto");
    const todaysDate = dayjs.tz(new Date(), "America/Toronto");

    const isTransfer =
      (todaysDate.isSame(checkInDate, "day") && todaysDate.hour() >= 18) ||
      todaysDate.isAfter(checkInDate, "day");

    let nightly_rate = <></>;
    if (this.state.booking_type === "thirty_day") {
      nightly_rate = (
        <span className=" text-sm  px-2 pt-1 font-normal font-display ">
          Nightly Rate:{" "}
          <span className="text-gray-500">
            {formatAmountForDisplay(this.state.rate / 30, "CAD", false)}
          </span>
        </span>
      );
    }

    let editFocus = "opacity-100";
    let bgFocus;
    if (this.state.editToggle || this.state.toggleBlur) {
      editFocus = "opacity-100";
      bgFocus = (
        <div
          onClick={
            this.state.editToggle
              ? this.handleEditBarToggle
              : this.toggleInvoices
          }
          className=" fade-bg w-full h-screen bg-black z-40  fixed"
        ></div>
      );
    }

    // let checkIn;
    // if (this.state.check_in == "") {
    //   checkIn = "-";
    // } else {
    //   checkIn = this.state.check_in.toISOString().substr(0, 10);
    // }

    // let checkOut;
    // if (this.state.check_out == "") {
    //   checkOut = "-";
    // } else {
    //   checkOut = this.state.check_out.toISOString().substr(0, 10);
    // }

    let Tags = [];
    let TagStep = [
      this.state.released,
      this.state.cancelled,
      this.state.confirmed,
      this.state.pets,
      this.state.manual_invoicing,
      this.state.auto_HST,
      this.state.cleaning_fee,
      this.state.hard_check_out,
      this.state.parking_required,
      this.state.has_add_ons,
    ];
    let TagLabel = [
      "Released",
      "Cancelled",
      "Confirmed",
      "Pets",
      " Manual Invoicing",
      "Auto HST",
      "Cleaning Fee",
      "Hard Check Out",
      "Parking",
      "Add-Ons Included",
    ];

    for (let i = 0; i < TagStep.length; i++) {
      if (TagStep[0] && TagLabel[i] === "Released") {
        //TagStep[0] denotes release, must be special css, is used for logic for cancellation
        Tags[i] = (
          <span
            className="px-3 mr-2 border border-orange-400 rounded-full bg-red-100 text-white-400 text-xs whitespace-nowrap inline-flex mb-2 sm:mb-0"
            key={i.toString()}
          >
            {TagLabel[i]}
          </span>
        );
      } else if (TagStep[i] === true && TagLabel[i] !== "Cancelled") {
        if (TagLabel[i] === "Add-Ons Included") {
          Tags[i] = (
            <span
              className="px-3 mr-2 border border-indigo-400 rounded-full bg-indigo-300 text-indigo-800 text-xs whitespace-nowrap inline-flex mb-2 sm:mb-0"
              key={i.toString()}
            >
              {TagLabel[i]}
            </span>
          );
        } else {
          Tags[i] = (
            <span
              className="px-3 mr-2 border border-gray-200 rounded-full bg-gray-100 text-gray-400 text-xs whitespace-nowrap inline-flex mb-2 sm:mb-0"
              key={i.toString()}
            >
              {TagLabel[i]}
            </span>
          );
        }
      } else if (
        TagStep[i] === true &&
        TagLabel[i] === "Cancelled" &&
        !TagStep[0]
      ) {
        Tags[i] = (
          <span
            className="px-3 mr-2 border border-red-200 rounded-full bg-red-100 text-black-400 text-xs whitespace-nowrap inline-flex mb-2 sm:mb-0"
            key={i.toString()}
          >
            {TagLabel[i]}
          </span>
        );
      } else {
        Tags[i] = <span key={i.toString()}></span>;
      }
    }

    let flag = <></>;
    if (this.state.flagged) {
      flag = (
        <BsFillFlagFill className="text-red-400 group-hover:text-gray-400 text-2xl mt-2 " />
      );
    }

    // let lights = <></>;
    // if (this.state.light == "red") {
    //   lights = <BsFillCircleFill style={{ fill: 'red' }} className="mt-2 items-center" />;
    // }
    // else if (this.state.light == "green") {
    //   lights = <BsFillCircleFill style={{ fill: 'green' }} className="mt-2 items-center" />;
    // }
    // else if (this.state.light == "blue") {
    //   lights = <BsFillCircleFill style={{ fill: 'blue' }} className="mt-2 items-center" />;
    // }

    let transfer = null;
    let transferLink = "";
    if (
      this.state?.booking_notes.toLowerCase().includes("transferred from") &&
      !this.state?.booking_notes.toLowerCase().includes("pre")
    ) {
      transfer = `| Suite Transferred From Booking: ${this.state.booking_notes.substring(
        this.state.booking_notes.indexOf("g") + 1,
        this.state.booking_notes.indexOf("|")
      )}`;
      transferLink = `/user/bookings/bookingprofile?id=${this.state.booking_notes
        .substring(
          this.state.booking_notes.indexOf("g") + 1,
          this.state.booking_notes.indexOf("|")
        )
        .replace(/%20/g, "")}`;
    } else if (
      this?.state.booking_notes.toLowerCase().includes("transferred to")
    ) {
      transfer = `| Suite Transferred To Booking: ${this.state.booking_notes.substring(
        this.state.booking_notes.indexOf("g") + 1,
        this.state.booking_notes.indexOf("|")
      )}`;
      transferLink = `/user/bookings/bookingprofile?id=${this.state.booking_notes
        .substring(
          this.state.booking_notes.indexOf("g") + 1,
          this.state.booking_notes.indexOf("|")
        )
        .replace(/%20/g, "")}`;
    }

    let cancelModal = <></>;
    if (this.state.cancelModalToggle) {
      cancelModal = (
        <Modal
          title="Delete Booking"
          content="Would you like to delete this booking?"
          onoff={this.state.cancelModalToggle}
          handleModalToggle={this.handleCancelModal}
          confirmButton={this.confirmCancelModal}
        ></Modal>
      );
    }

    let newCheckInModal = <></>;
    let confirmCheckInModalMsg = (
      <span>
        Would you like to change the current check in date of
        <span className="font-bold">
          {" "}
          {dayjs(this.state.check_in).format("YYYY-MM-DD")}
        </span>{" "}
        to
        <span className="font-bold">
          {" "}
          {dayjs(this.state.new_check_in).format("YYYY-MM-DD")}
        </span>
        ?
      </span>
    );

    if (this.state.newCheckInModalToggle) {
      newCheckInModal = (
        <Modal
          title="Confirm Check In Change"
          content={confirmCheckInModalMsg}
          onoff={this.state.newCheckInModalToggle}
          handleModalToggle={this.handleNewCheckInModal}
          confirmButton={this.handleConfirmEarly}
        ></Modal>
      );
    }

    let hardChekout = <></>;
    if (this.state.hard_checkout) {
      hardChekout = (
        <span className="flex flex-row items-center mr-4 pt-1 align-middle font-hairline text-sm px-2 text-green-800">
          <MdDoneAll style={{ fill: "green" }} className="mr-2" />
          Hard Check Out Confirmed
        </span>
      );
    }
    let keyStatus = <></>;
    keyStatus = (
      <span
        className={`font-bold mx-1 text-${
          this.state.keys_confirm ? "green" : "red"
        }-500`}
      >
        K
      </span>
    );

    let cleaningStatus = <></>;
    cleaningStatus = (
      <span
        className={`font-bold mx-1 text-${
          this.state.cleaning_confirm ? "green" : "red"
        }-500`}
      >
        C
      </span>
    );

    let paymentStatus = <></>;
    paymentStatus = (
      <span
        className={`font-bold mx-1 text-${
          this.state.payment_confirm ? "green" : "red"
        }-500`}
      >
        $
      </span>
    );

    return (
      <div className=" font-display bg-gray-50 lg:rounded-tl-lg pt-12 lg:pt-0 ">
        {cancelModal}
        {newCheckInModal}
        {this.state.toggleFlag && !this.state.flagged && (
          <FlagModal
            open={this.state.toggleFlag}
            closeModal={this.handleFlagModal}
            flagged={this.handleFlagged}
            // submitFlag = {this.toggleFlag}
            bookingId={this.state.booking_id ? this.state.booking_id : ""}
            flag_reason={this.setFlagReason}
          />
        )}
        {this.state.toggleAddOns && (
          <AddOnsModal
            open={this.state.toggleAddOns}
            closeModal={this.handleAddOns}
            hasAddOns={this.state.has_add_ons}
            bookingAddOns={this.state.addOns}
            handleAddOnsData={this.handleAddOnsData}
            bookingId={this.state.booking_id ? this.state.booking_id : ""}
            cleaningFrequency={this.state.cleaning_frequency}
          />
        )}
        {this.state.editExpiryToggle && (
          <ReleaseModal
            open={this.state.editExpiryToggle}
            closeModal={this.handleExpiryToggle}
            bookingId={+this.state.booking_id}
          />
        )}
        {this.state.editToggle && (
          <EditBooking
            stateChange={this.handleInputChange}
            handleDateChange={this.handleDateChange}
            toggle={this.state.editToggle}
            handleEditBarToggle={this.handleEditBarToggle}
            bookingData={this.state}
            handleToggleChange={this.handleToggleChange}
            ErrorNote={this.props.ErrorNote}
          />
        )}
        {this.state.toggleNewInvoice && (
          <NewInvoice
            toggle={this.state.toggleNewInvoice}
            handleEditBarToggle={this.toggleInvoices}
            bookingId={this.state.booking_id}
            updateList={false}
            fromBooking={true}
          />
        )}
        {this.state.toggleInvoice && (
          <InvoiceQuickView
            handleModalToggle={this.handleInvoiceView}
            onoff={this.state.toggleInvoice}
            info={this.state.info}
          />
        )}
        {this.state.toggleHistory && (
          <Modal
            onoff={this.state.toggleHistory}
            title={this.state.historyTitle}
            handleModalToggle={this.handleHistoryModal}
            content={JSON.stringify(this.state.historyContent, null, 2)}
          />
        )}
        {this.state.editPortalToggle && (
          <EditPortalModal
            onoff={this.state.editPortalToggle}
            handleModalToggle={this.handleEditPortalToggle}
            toggleEarlyCheckInModal={this.handleNewCheckInModal}
            toggleCheckOutModal={() =>
              this.handleToggleChange(
                "toggleCheckOut",
                this.state.toggleCheckOut
              )
            }
            toggleFlagModal={this.handleFlagModal}
            toggleTransferModal={() =>
              this.handleToggleChange(
                "toggleTransfer",
                this.state.toggleTransfer
              )
            }
            toggleRateModal={() =>
              this.handleToggleChange("toggleRate", this.state.toggleRate)
            }
            toggleAddOnsModal={() => this.handleAddOns()}
            toggleCancelModal={() => this.handleCancelModal()}
            toggleEditModal={() => this.handleEditBarToggle()}
            toggleExpiryModal={() => this.handleExpiryToggle()}
            checkIn={this.state.check_in}
            id={+this.state.booking_id}
            checkOut={this.state.check_out}
            suiteName={this.state.unit_name}
            rentalsUnitedID={this.state.ru_reservation_id}
            monthlyRate={this.state.rate}
            flagged={this.state.flagged}
            toggleFlag={this.toggleFlag}
            userType={this.state.userType}
          />
        )}
        {bgFocus}

        {this.state.toggleTransfer && (
          <TransferModal
            handleModalToggle={() =>
              this.handleToggleChange(
                "toggleTransfer",
                this.state.toggleTransfer
              )
            }
            handleSwapToggle={this.handleSwapToggle}
            handleMultipleToggle={this.handleMultipleToggle}
            onoff={this.state.toggleTransfer}
            title={`${
              !isTransfer ? "Pre Check-In Transfer" : "Post Check-In Transfer"
            }`}
            rate={this.state.rate}
          />
        )}
        {this.state.toggleSwap && (
          <SwapModal
            handleModalToggle={() =>
              this.handleToggleChange("toggleSwap", this.state.toggleSwap)
            }
            swapData={this.state.swapData}
            multipleData={this.state.multipleData}
            handleMultipleToggle={this.handleMultipleToggle}
            errorMessage={this.state.errorMessage}
            onoff={this.state.toggleSwap}
            title="Swap Booking"
          />
        )}
        {this.state.toggleMultipleBookings && (
          <SwapModal
            handleModalToggle={() =>
              this.handleToggleChange(
                "toggleMultipleBookings",
                this.state.toggleMultipleBookings
              )
            }
            swapData={this.state.swapData}
            onoff={this.state.toggleSwap}
            title="Swap Booking"
          />
        )}
        <Suspense fallback={<div>Loading...</div>}>
          <RateModal
            handleModalToggle={() =>
              this.handleToggleChange("toggleRate", this.state.toggleRate)
            }
            onoff={this.state.toggleRate}
            title="Rate Change"
            bookingId={Number(
              new URLSearchParams(window.location.search).get("id")
            )}
            checkIn={this.state.check_in}
          />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <CancelModal
            handleModalToggle={() =>
              this.handleToggleChange("toggleCancel", this.state.toggleCancel)
            }
            onoff={this.state.toggleCancel}
            title="Cancel Booking"
          />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <CheckOutModal
            booking_data={this.state}
            handleModalToggle={() =>
              this.handleToggleChange(
                "toggleCheckOut",
                this.state.toggleCheckOut
              )
            }
            onoff={this.state.toggleCheckOut}
            title="Check Out"
          />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <BreadCrumb
            rootPage="Bookings"
            subPage={`Profile #${this.state.booking_id}`}
            sideButton="agreement"
            buttonAction={this.handleAgreement}
          />
        </Suspense>

        <div
          className={`${editFocus} px-4 lg:px-10 max-w-screen-xl mx-auto border rounded-lg pt-10 pb-32 bg-white`}
        >
          {/* Heading Info Area  */}
          <div className="flex flex-col lg:flex-row mb-2 lg:pb-0">
            {/* Left Side  */}
            <div className="flex flex-col lg:w-2/3 mb-4">
              <div className="flex flex-row items-center space-x-3">
                <h1 className=" text-3xl lg:text-2xl px-2 pt-3 text-gray-700 font-black font-display">
                  Booking ID #{this.state.booking_id}{" "}
                  {transfer && (
                    <a href={transferLink.replace(/\s+/g, "")} target="_blank">
                      {transfer}
                    </a>
                  )}
                </h1>
                {flag}
              </div>
              <div className="flex flex-col lg:flex-row text-sm text-gray-600">
                <h2 className="px-2 pt-1  font-normal font-display ">
                  Check In: {dayjs(this.state.check_in).format("YYYY-MM-DD")}
                </h2>
                <h2 className=" px-2 pt-1  font-normal font-display ">
                  Check Out: {dayjs(this.state.check_out).format("YYYY-MM-DD")}
                </h2>
                <h2 className=" px-2 pt-1  font-normal font-display ">
                  <strong>NTV Date: {this.state.NTVDate}</strong>
                </h2>
                {this.state.extension_date && (
                  <h2 className=" px-2 pt-1  font-normal font-display text-green-500">
                    <strong>
                      Pending Extension: {this.state.extension_date}
                    </strong>
                  </h2>
                )}
              </div>
              <div className="flex flex-col lg:flex-row text-sm text-gray-600">
                <h2 className="px-2 pt-1  font-normal font-display ">
                  Created At (EST): {this.state.created_at}
                </h2>
                <h2 className=" px-2 pt-1  font-normal font-display ">
                  Updated At (EST): {this.state.updated_at}
                </h2>
              </div>
              <div className="flex flex-col lg:flex-row text-sm text-gray-600">
                <h2 className="px-2 pt-1  font-normal font-display ">
                  <strong>Expiry: </strong>
                  {this.state.expiry}
                </h2>
              </div>

              {/* <ViewSheet id={this.state.unit_id} url={this.props}/> */}
              {/* <UnitInfo UnitData={this.state} />  */}
              <div className="flex ml-3">
                <ViewSheet
                  booking_id={+this.state.booking_id}
                  unit_id={null}
                  name="View Booking Portal Sheet"
                />
                <div className="ml-3">
                  <ViewSheet
                    unit_id={this.state.unit_id}
                    name="View Unit Info Sheet"
                  />
                </div>
              </div>

              <div className="mt-2 mb-3">
                <span className="flex-row justify-center my-3 ml-4">
                  <span className="text-sm font-bold h-6 lg:px-5 py-2 rounded-md shadow-md">
                    {keyStatus} {cleaningStatus} {paymentStatus}
                  </span>
                </span>
                {this.state.ru_reservation_id && (
                  <button
                    type="button"
                    className="bg-gradient-to-r from-blue-400 to-pink-500 text-white font-semibold px-6 py-3 rounded-md ml-5"
                  >
                    RENTALS UNITED BOOKING
                  </button>
                )}
              </div>
              <div className="flex flex-row w-full justify-start mt-3 pl-1 flex-wrap ">
                {Tags}
              </div>

              <span className=" text-sm px-2 pt-4 font-normal font-display ">
                Corporate:{" "}
                <span className="text-gray-500">
                  {this.state.corporate_name}
                </span>
              </span>

              <span className="text-sm px-2 pt-1 font-normal font-display">
                Required Payment For Access:{" "}
                {this.state.corporate_name != "N/A" ? (
                  <span
                    className={
                      !this.state.required_payment_for_access
                        ? "text-green-500"
                        : "text-red-500"
                    }
                  >
                    {!this.state.required_payment_for_access
                      ? "Does Not Require First Rent Payment"
                      : "Requires First Rent Payment"}
                  </span>
                ) : (
                  <span className="text-red-500">
                    Requires First Rent Payment
                  </span>
                )}
              </span>

              <span className=" text-sm  px-2 pt-1 font-normal font-display ">
                Unit Name:{" "}
                <span className="text-gray-500">{this.state.unit_name}</span>
              </span>

              <span className=" text-sm  px-2 pt-1 font-normal font-display ">
                Tenant Name:{" "}
                <span className="text-gray-500">{this.state.tenant_name}</span>
              </span>

              <span className=" text-sm  px-2 pt-1 font-normal font-display ">
                Cleaning Frequency:{" "}
                <span className="text-gray-500">
                  {this.state.cleaning_frequency}
                </span>
              </span>

              <span className=" text-sm  px-2 pt-1 font-normal font-display ">
                {this.state.booking_type.toLowerCase() == "monthly"
                  ? "Monthly "
                  : "30 Day "}{" "}
                Rate:{" "}
                <span className="text-gray-500">
                  {formatAmountForDisplay(this.state.rate, "CAD", false)}
                </span>
              </span>
              {nightly_rate}

              <span className=" text-sm  px-2 pt-1 font-normal font-display ">
                Key Code:{" "}
                <span className="text-gray-500">{this.state.keyCode}</span>
              </span>

              <span className=" mr-4 pt-1 align-middle font-hairline text-sm px-2">
                Booking Notes:
                <div className="text-red-300">
                  {this.state.booking_notes
                    ? this.state.booking_notes
                    : "No Booking Notes"}
                </div>
              </span>
              {this.state.flag_reason && (
                <span className=" mr-4 pt-1 align-middle font-hairline text-sm px-2">
                  Flag Reason:
                  <div className="text-red-300">
                    {this.state.flag_reason
                      ? this.state.flag_reason
                      : "No Flag Reason"}
                  </div>
                </span>
              )}
              {hardChekout}
              {/* Removed condition for building agreement just to show everywhere */}
              {/* {this.state.eligibleAgreement && ( */}
              <div className="mt-4">
                <Button
                  variant="outlined"
                  color="forestGreen"
                  endIcon={<MdOpenInNew />}
                  disableElevation
                  onClick={this.handleBookingAgreement}
                >
                  Booking Agreement
                </Button>
              </div>
              {/* )} */}
            </div>

            {/* Right Side */}
            <div className="flex flex-col lg:w-1/3 ">
              <Suspense fallback={<div>Loading...</div>}>
                <EditOptionPanel
                  handleEditBarToggle={this.handleEditPortalToggle}
                  // handleEditBarToggle={this.handleEditBarToggle}
                  parentData={this.state}
                  panelType="Booking"
                  handleFlag={this.toggleFlag}
                  handleState={this.handleToggleChange}
                  cancelModalToggle={this.handleCancelModal}
                />
              </Suspense>
              <div className="flex justify-end">
                <div className="min-w-40 rounded ml-4 pt-2 px-4 ">
                  <ul className="pb-2">
                    <li className="text-right text-sm">Associated Tenants:</li>
                    {this.state.other_tenants.map((id: any) => {
                      let tenant = this.state.tenants.find(
                        (ten: any) => ten?.id == id
                      );
                      if (!tenant) {
                        return;
                      }
                      return (
                        <li
                          className="text-right text-gray-600 text-sm"
                          key={tenant.id}
                        >
                          {`${tenant.first_name} ${tenant.last_name}`}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <TabList warning={false} hasSalesOrder={this.state.reduceTabSize}>
            {generateTabs()}
            {/* <Label label="Contact" key="Contact">
              <Suspense fallback={<div>Loading...</div>}>
                <ContactProfileCard contactData={this.state} />
              </Suspense>
            </Label>
            <Label label="Contact" key="Contact">
              <Suspense fallback={<div>Loading...</div>}>
                <ContactProfileCard contactData={this.state} />
              </Suspense>
            </Label> */}
          </TabList>
        </div>

        <KeysInfo data={this.state} />
      </div>
    );
  }
}

export default withHooksHOC(BookingProfile);
